import '../../style/dialog-form.css';

import { useState } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import {
  CheckboxField,
  DropdownField,
  PropertyLabel,
  TextField
} from '../Core';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { useAppSelector } from '../../hooks';
import styles from './styles.module.scss';

function AmplitudeSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.AMPLITUDE,
    panelId: formId,
    includeInactive: true
  });
  const org = useAppSelector((state) => state.accounts.organization);

  const meta = integration?.data.metadata ?? {};
  // Local/draft integration settings
  const [apiKey, setAPIKey] = useState(meta.api_key ?? '');
  const [testAPIKey, setTestAPIKey] = useState(meta.test_api_key ?? '');
  const [identifyUser, setIdentifyUser] = useState(meta.identify_user ?? false);
  const [trackFields, setTrackFields] = useState(meta.track_fields ?? false);
  const [sessionReplay, setSessionReplay] = useState(
    meta.session_replay ?? false
  );
  const [euServer, setEUServer] = useState(meta.eu_server ?? false);

  const [isPartial, setIsPartial] = useState(false);

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial = !apiKey;
      setIsPartial(partial);
      if (partial) return;
    }

    const metadata = {
      api_key: apiKey,
      test_api_key: testAPIKey,
      identify_user: identifyUser,
      track_fields: trackFields,
      session_replay: sessionReplay,
      eu_server: euServer
    };
    return { isUpdate: integration?.data, metadata };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.AMPLITUDE]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <form>
        <div>
          <PropertyLabel label='API Key' />
          <TextField
            className='dialog-form-input'
            value={apiKey || ''}
            onChange={setAPIKey}
            error={isPartial && !apiKey}
          />
        </div>
        <div>
          <PropertyLabel label='Test API Key (Optional)' />
          <TextField
            className='dialog-form-input'
            value={testAPIKey || ''}
            onChange={setTestAPIKey}
          />
        </div>
        <div>
          <PropertyLabel label='Identify User in Amplitude' />
          <DropdownField
            className='dialog-form-input'
            selected={identifyUser.toString()}
            onChange={(event: any) =>
              setIdentifyUser(event.target.value === 'true')
            }
            options={[
              { value: 'false', display: 'No' },
              { value: 'true', display: 'Yes' }
            ]}
          />
        </div>
        {!org?.enterprise_features.hipaa && (
          <>
            <div>
              <PropertyLabel label='Track field entries' />
              <DropdownField
                className={styles.marginBottom}
                selected={trackFields.toString()}
                onChange={(event: any) =>
                  setTrackFields(event.target.value === 'true')
                }
                options={[
                  { value: 'false', display: 'No' },
                  { value: 'true', display: 'Yes' }
                ]}
              />
            </div>
            <div>
              <CheckboxField
                text='Enable Session Replay'
                checked={sessionReplay}
                onChange={(isChecked) => setSessionReplay(isChecked)}
              />
            </div>
          </>
        )}
        <div>
          <CheckboxField
            text='European Data Residency'
            checked={euServer}
            onChange={(isChecked) => setEUServer(isChecked)}
          />
        </div>
      </form>
    </IntegrationsSidebar>
  );
}

export default AmplitudeSettingsSidebar;
