import { ChangeEvent, memo, useMemo, useRef, useState } from 'react';

import Dialog from '../Dialog';
import { Positive } from '../Core/Button';
import useFeatheryRedux from '../../redux';
import Label from '../Dialog/Label';
import { useAppSelector } from '../../hooks';
import { openTab } from '../../utils/domOperations';
import { InfoIcon } from '../Icons';
import { Button } from '../Core/Button/button';

interface BulkUploadDataEntry {
  user_id: string;
  [key: string]: string;
}

interface UploadSubmissionsCSVModalProps {
  panelId: string;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  refreshSubmissionData?: () => void;
}

function UploadSubmissionsCSVModal({
  panelId,
  show,
  setShow,
  refreshSubmissionData
}: UploadSubmissionsCSVModalProps) {
  const {
    bulkUpload,
    toasts: { addToast }
  } = useFeatheryRedux();

  const servars = useAppSelector((state) => state.fields.servars) || [];
  const hiddenFields =
    useAppSelector((state) => state.fields.hiddenFields) || [];

  const fileInput = useRef<HTMLInputElement>(null);
  const [bulkSubmissionsFile, setBulkSubmissionsFile] = useState<File | null>(
    null
  );
  const [bulkSubmissionsData, setBulkSubmissionsData] = useState<
    BulkUploadDataEntry[] | null
  >(null);
  const [error, setError] = useState('');

  const validFieldKeys = useMemo(() => {
    const servarKeys = servars.map((servar) => servar.key);
    const hiddenFieldKeys = hiddenFields.map((hf) => hf.key);

    return new Set([...servarKeys, ...hiddenFieldKeys]);
  }, [servars, hiddenFields]);

  const reset = () => {
    setBulkSubmissionsFile(null);
    setShow(false);
  };

  const handleCSVUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setBulkSubmissionsFile(file);
      setError('');

      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        const payload = event.target?.result as string;

        const rows = payload.split('\n').map((line) => {
          const values = [];
          let startValueIndex = 0;
          let inQuotes = false;

          for (let i = 0; i < line.length; i++) {
            if (line[i] === '"') {
              inQuotes = !inQuotes;
            } else if (line[i] === ',' && !inQuotes) {
              values.push(
                line.slice(startValueIndex, i).trim().replace(/^"|"$/g, '')
              );
              startValueIndex = i + 1;
            }
          }

          values.push(line.slice(startValueIndex).trim().replace(/^"|"$/g, ''));
          return values;
        });

        if (rows.length <= 1) {
          setError('This file has no results data to parse');
          return;
        }

        const headers = rows[0].map((header) => header.trim());
        const normalizedHeaders = headers.map((header) => header.toLowerCase());

        const userIdIndex = normalizedHeaders.indexOf('user id');
        if (userIdIndex === -1) {
          setError(
            'CSV file must include a "User ID" (case-insensitive) column'
          );
          return;
        }

        const resultsData = rows
          .slice(1)
          .map((row) => {
            const entry: BulkUploadDataEntry = { user_id: row[userIdIndex] };

            row.forEach((item, index) => {
              const key = headers[index];
              const value = item.trim();

              if (index === userIdIndex) return;
              if (validFieldKeys.has(key) && value !== '') {
                entry[key] = value;
              }
            });

            return entry;
          })
          .filter(
            (entry): entry is BulkUploadDataEntry => entry.user_id.trim() !== ''
          );

        if (resultsData.length === 0) {
          setError('No valid results data found in the CSV file');
          return;
        }

        setBulkSubmissionsData(resultsData);
      });

      reader.addEventListener('error', () => {
        setError('Error reading file');
      });

      reader.readAsText(file);
    }
  };

  const handleSubmit = () => {
    bulkUpload({
      panelId,
      data: bulkSubmissionsData
    }).then(() => {
      addToast({
        text: <>Bulk Upload Successful</>
      });
      if (refreshSubmissionData) refreshSubmissionData();
      reset();
    });
  };

  return (
    <Dialog
      isOpen={show}
      onClose={reset}
      header={() => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <h4 style={{ marginBottom: 0 }}>Upload Submissions CSV</h4>
          <div
            onClick={() =>
              openTab(
                'https://docs.feathery.io/platform/build-forms#upload-submissions-from-csv-file'
              )
            }
            style={{
              color: 'var(--grey-70)',
              fontSize: '14px',
              fontWeight: 400,
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <InfoIcon width={17} height={17} />
            <>Upload Instructions</>
          </div>
        </div>
      )}
      alignCenter
      shouldShowCloseIcon
    >
      <div
        style={{
          paddingBlockStart: 24
        }}
      >
        {bulkSubmissionsFile && (
          <Label style={{ margin: 0 }}>{bulkSubmissionsFile.name}</Label>
        )}
        {error && (
          <p style={{ color: 'var(--red-border)', paddingTop: 3, margin: 0 }}>
            {error}
          </p>
        )}
      </div>
      <div
        className='dialog-form-action text-center'
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          variant='outline-primary'
          onClick={() => fileInput.current?.click()}
        >
          Choose CSV
        </Button>
        <input
          ref={fileInput}
          type='file'
          onChange={handleCSVUpload}
          accept='.csv'
          style={{
            position: 'absolute',
            bottom: 0,
            opacity: 0,
            zIndex: -1
          }}
        />
        {bulkSubmissionsFile && (
          <Positive
            disabled={bulkSubmissionsData === null || Boolean(error)}
            onClick={handleSubmit}
          >
            Submit
          </Positive>
        )}
      </div>
    </Dialog>
  );
}

export default memo(UploadSubmissionsCSVModal);
