import Switch from '..';
import styles from './styles.module.scss';
import { ComponentProps } from 'react';

interface YesNoSwitchProps extends ComponentProps<typeof Switch> {
  switchStyle?: React.CSSProperties;
  customYesLabel?: string;
  customNoLabel?: string;
}

function YesNoSwitch({
  checked,
  switchStyle,
  customYesLabel = 'Yes',
  customNoLabel = 'No',
  ...props
}: YesNoSwitchProps) {
  const noClass = checked ? styles.disabled : styles.enabled;
  const yesClass = checked ? styles.enabled : styles.disabled;
  return (
    <label className={styles.switchContainer} style={switchStyle}>
      <span className={noClass}>{customNoLabel}</span>
      <Switch checked={checked} className={styles.switch} {...props} />
      <span className={yesClass}>{customYesLabel}</span>
    </label>
  );
}

export default YesNoSwitch;
