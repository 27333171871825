import { memo } from 'react';

import {
  AIStarIcon,
  DocsTabIcon,
  FormsDashboardIcon,
  LogoutIcon,
  SettingsIcon,
  SplitTestsIcon,
  TasksIcon,
  ThemesIcon,
  UpgradeIcon,
  UsersIcon
} from '../../Icons';
import { Link } from 'react-router-dom';

import NavTab from './NavTab';
import useLogout from '../../../utils/useLogout';
import { useAppSelector } from '../../../hooks';
import { Logo } from './Logo';

function GlobalNavigation({ active }: any) {
  const logout = useLogout();

  const org = useAppSelector((state) => state.accounts.organization);
  const account = useAppSelector((state) => state.accounts.account);
  const themeAllowed =
    account.role === 'admin' || account.permission_edit_theme;
  const isEnterprise = org?.tier === 4;
  const abAllowed = isEnterprise && org?.enterprise_features.ab_testing;
  const aiExtractionAllowed =
    isEnterprise && org?.enterprise_features.ai_documents;
  const tasksAllowed = isEnterprise && org?.enterprise_features.collaboration;

  return (
    <nav className='page-nav'>
      <div className='page-nav-menu'>
        <Link to='/' className='logo'>
          <Logo />
        </Link>
        <NavTab
          label='Forms'
          link='/forms'
          active={active}
          icon={<FormsDashboardIcon />}
        />
        {isEnterprise && org?.enterprise_features.workspaces && (
          <NavTab
            label='Workspaces'
            link='/workspaces'
            active={active}
            icon={<FormsDashboardIcon />}
          />
        )}
        {!org?.whitelabel && (
          <>
            <NavTab
              label='Documents'
              link='/documents'
              active={active}
              icon={<DocsTabIcon />}
            />
            <NavTab
              label='AI'
              link='/ai'
              active={active}
              icon={<AIStarIcon height={24} color='transparent' />}
              disabledMessage={
                aiExtractionAllowed
                  ? undefined
                  : 'Reach out to sales to access document extractions'
              }
            />
          </>
        )}
        {account.role !== 'viewer' && (
          <>
            {!org?.disabled_global_tabs.includes('themes') && (
              <NavTab
                label='Themes'
                link='/themes'
                active={active}
                icon={<ThemesIcon />}
                disabledMessage={
                  themeAllowed
                    ? ''
                    : "You don't have permission to access themes"
                }
              />
            )}
            {!org?.disabled_global_tabs.includes('ab_tests') && (
              <NavTab
                label='A/B Tests'
                link='/ab-tests'
                active={active}
                icon={<SplitTestsIcon />}
                disabledMessage={
                  abAllowed
                    ? ''
                    : 'Reach out to sales to access A/B testing and form analytics'
                }
              />
            )}
            {!org?.disabled_global_tabs.includes('all_users') && (
              <NavTab
                label='All Users'
                link='/users'
                active={active}
                icon={<UsersIcon width={24} />}
                disabledMessage={
                  isEnterprise
                    ? ''
                    : 'Reach out to sales to access global result views'
                }
              />
            )}
          </>
        )}
        {!org?.whitelabel && (
          <NavTab
            label='Tasks'
            link='/tasks'
            active={active}
            icon={<TasksIcon width={24} />}
            disabledMessage={
              tasksAllowed ? '' : 'Reach out to sales to access the task view'
            }
          />
        )}
      </div>
      <div className='page-nav-menu'>
        {org?.tier < 4 && (
          <NavTab
            label='Upgrade'
            link='/settings/billing'
            icon={<UpgradeIcon />}
            showBackground
          />
        )}
        {account.role !== 'viewer' && (
          <NavTab
            label='Settings'
            link='/settings'
            active={active}
            icon={<SettingsIcon />}
          />
        )}
        <NavTab label='Logout' icon={<LogoutIcon />} onClick={logout} />
      </div>
    </nav>
  );
}

export default memo(GlobalNavigation);
