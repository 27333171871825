import { useMemo, useState } from 'react';

import styles from './styles.module.scss';
import classNames from 'classnames';

export default function useFilterTag(allIntegrations: any[], tags: string[]) {
  const [filterTag, setFilterTag] = useState('All');

  const filterPills = useMemo(() => {
    const pills = ['All', ...tags]
      .map((label) => {
        const selected = filterTag === label;
        const count = allIntegrations.filter(
          (integ) => integ.tags.includes(label) || label === 'All'
        ).length;
        if (count === 0) return null;
        return (
          <FilterTag
            key={label}
            label={label}
            count={count}
            selected={selected}
            onClick={() => setFilterTag(selected ? 'All' : label)}
          />
        );
      })
      .filter((entry) => entry);
    return (
      <div className={styles.categoryContainer}>
        <span className={styles.categoryTitle}>Categories</span>
        {pills}
      </div>
    );
  }, [filterTag, setFilterTag]);

  return { filterTag, filterPills };
}

function FilterTag({
  label,
  selected,
  count,
  onClick = () => {}
}: {
  label: string;
  selected: boolean;
  count: number;
  onClick: any;
}) {
  return (
    <div
      className={classNames(styles.filterTag, selected && styles.selected)}
      onClick={onClick}
    >
      <span>{label}</span>
      {count}
    </div>
  );
}
