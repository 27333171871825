import logoLoader from '../../Assets/logoLoader.json';
import Lottie from 'lottie-react';
import styles from './styles.module.scss';
import testIds from '../../../utils/testIds';
import { useAppSelector } from '../../../hooks';
import { Spinner } from '../Spinner';
import { getWorkspaceJwt } from '../../../utils/useLogout';

export default function LogoLoader() {
  const org = useAppSelector((state) => state.accounts.organization);

  return org?.whitelabel || getWorkspaceJwt() ? (
    <Spinner className={styles.loader} />
  ) : (
    <Lottie
      data-testid={testIds.logoLoader}
      className={styles.loader}
      animationData={logoLoader}
      loop
      autoplay
      renderer='svg'
    />
  );
}
