import { memo } from 'react';

import styles from '../styles.module.scss';
import testIds from '../../../../utils/testIds';
import { useAppSelector } from '../../../../hooks';

const BASE_ICON_URL = 'https://feathery.s3.us-west-1.amazonaws.com/templates/';

function TemplateSelectCard({
  template,
  index,
  customIcon,
  onSelect = () => {}
}: any) {
  if (template === undefined) return null;
  if (template === 'generate')
    return <GenerateCard index={index} template={template} />;
  if (template === 'finserv')
    return (
      <FinancialServicesLibraryCard
        index={index}
        template={template}
        onSelect={onSelect}
      />
    );
  if (template === 'blank' || customIcon === 'base') {
    const label = customIcon === 'base' ? template : 'Blank Form';
    return (
      <BlankCard
        index={index}
        template={template}
        onSelect={onSelect}
        label={label}
      />
    );
  }

  const fileName = template.replaceAll(' ', '+');
  return (
    <>
      <div
        className={styles.card}
        style={{ gridColumn: `${index + 1}/${index + 2}` }}
      >
        <img
          data-testid={testIds.template(template)}
          src={customIcon || `${BASE_ICON_URL}${fileName}.jpg`}
          alt='Template'
          onClick={() => onSelect(template)}
        />
        <div className={styles.label}>{template}</div>
      </div>
    </>
  );
}

export function BlankCard({ index, template, onSelect, label }: any) {
  return (
    <>
      <div
        onClick={() => onSelect(template)}
        className={styles.card}
        style={{ gridColumn: `${index + 1}/${index + 2}` }}
      >
        <div className={styles.imageWrapper}>
          <img
            data-testid={testIds.template(template)}
            src={`${BASE_ICON_URL}blank_template.png`}
            alt='Template'
          />
        </div>
        <div className={styles.label}>{label}</div>
      </div>
    </>
  );
}

export function GenerateCard({ index, template }: any) {
  return (
    <>
      <div
        onClick={() => {
          window.open('https://ai.feathery.io/', '_blank');
        }}
        className={styles.card}
        style={{ gridColumn: `${index + 1}/${index + 2}` }}
      >
        <div className={styles.imageWrapper}>
          <img
            data-testid={testIds.template(template)}
            src={`${BASE_ICON_URL}generate_ai.png`}
            alt='Generate with AI'
          />
        </div>
        <div className={styles.label}>Generate with AI</div>
      </div>
    </>
  );
}

export function FinancialServicesLibraryCard({
  index,
  template,
  onSelect
}: any) {
  const isQuikEnabled = useAppSelector((state) => {
    const org = state.accounts.organization;
    return !!(org && org.enterprise_features.quik);
  });

  return (
    <div style={{ cursor: isQuikEnabled ? 'default' : 'not-allowed' }}>
      <div
        onClick={() => {
          onSelect('Financial Services Form Library');
        }}
        className={styles.card}
        style={{
          gridColumn: `${index + 1}/${index + 2}`,
          pointerEvents: isQuikEnabled ? 'auto' : 'none'
        }}
      >
        <div className={styles.imageWrapper}>
          <img
            data-testid={testIds.template(template)}
            src={`${BASE_ICON_URL}financial_services.png`}
            alt='Financial Services Form Library'
          />
        </div>
        <div className={styles.label}>Financial Services Form Library</div>
      </div>
    </div>
  );
}

export default memo(TemplateSelectCard);
