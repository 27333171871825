import useFeatheryRedux from '../../../redux';
import styles from './styles.module.scss';
import { Button } from '../Button/button';
import { useEffect, useState } from 'react';

const CopyableText = ({
  text,
  toastMessage
}: {
  text: string;
  toastMessage?: string;
}) => {
  const {
    toasts: { addToast }
  } = useFeatheryRedux();
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 1_000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);
  return (
    <div className={styles.container}>
      <div className={styles.text}>{text}</div>
      <Button
        variant='text-primary'
        size='sm'
        onClick={() => {
          navigator.clipboard.writeText(text).then(() => {
            setCopied(true);
            addToast({
              text: (
                <strong>{toastMessage || 'Text copied to clipboard.'}</strong>
              )
            });
          });
        }}
      >
        {copied ? 'Copied' : 'Copy'}
      </Button>
    </div>
  );
};

export default CopyableText;
