import { components, MultiValueGenericProps } from 'react-select';
import { Button } from '../../Core/Button/button';
import { SettingsIcon } from '../../Icons';

export default function ConditionalAttachment(
  props: MultiValueGenericProps<any>
) {
  const { onSettingsClick } = props.selectProps as any;
  return (
    <div className='relative flex items-center'>
      <components.MultiValueLabel {...props} />
      <Button
        onClick={(event) => {
          event.preventDefault();
          onSettingsClick(props.data.value);
        }}
        variant='ghost'
        size='icon'
        className='h-[26px] text-grey-600 hover:bg-white hover:text-inherit w-[22px]'
      >
        <SettingsIcon fill='currentColor' height={16} width={16} />
      </Button>
    </div>
  );
}
