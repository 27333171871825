import styles from '../styles.module.scss';
import {
  DropdownField,
  InlineTooltip,
  TextField,
  YesNoSwitch
} from '../../../components/Core';
import { Tooltip } from '../../../components/Core/Tooltip/Tooltip';
import { TrashIcon } from '../../../components/Icons';
import FaviconUpload from '../components/FaviconUpload';
import useFeatheryRedux from '../../../redux';
import { onFreePlan } from '../../../components/PricingPlans/plans';
import { useAppSelector } from '../../../hooks';
import { useState } from 'react';
import { isValidUrl } from '../../../utils/validate';
import { openTab } from '../../../utils/domOperations';
import { REGION } from '../../../api/utils';
import { Button } from '../../../components/Core/Button/button';

interface CustomDomain {
  url: string;
  apex_form: string | null;
}

interface FormOption {
  value: string;
  display: string;
}

export default function BrandingTab() {
  const {
    editOrganization,
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();

  const org = useAppSelector((state) => state.accounts.organization);
  const panels = useAppSelector((state) => state.panels.panels);

  const [showBrand, setShowBrand] = useState(org?.show_brand ?? true);
  const [customSubdomain, setCustomSubdomain] = useState(org?.slug ?? '');
  const [customDomains, setCustomDomains] = useState<CustomDomain[]>(
    org?.custom_domains?.length
      ? org.custom_domains
      : [{ url: '', apex_form: null }]
  );
  const [subdomainApexForm, setSubdomainApexForm] = useState(
    org?.apex_form ?? ''
  );

  const enterprise = org?.tier === 4;
  const freeTier = onFreePlan(org);
  const subdomainApexEnabled = !freeTier && customSubdomain.length > 0;

  const formOptions: FormOption[] = [
    { value: '', display: 'None' },
    ...Object.values(panels).map(({ id, key }) => ({
      value: id,
      display: key
    }))
  ];

  const handleSaveSubdomain = async () => {
    if (freeTier) return;

    try {
      await editOrganization({
        slug: customSubdomain || null,
        apex_form: subdomainApexForm || null
      }),
        addInfoToast('Subdomain and apex form updated');
    } catch {
      addErrorToast({ title: 'Invalid subdomain' });
    }
  };

  const handleSaveCustomDomains = () => {
    if (!enterprise) return;

    // Filter out empty configurations
    const domainsToSave = customDomains.filter(
      (domain) => domain.url.trim() !== ''
    );

    const hasInvalidDomains = domainsToSave.some(
      (domain) => !isValidUrl(domain.url)
    );

    if (hasInvalidDomains) {
      addErrorToast({ title: 'Can only add valid domains' });
      return;
    }

    editOrganization({ custom_domains: domainsToSave })
      .then(() => addInfoToast('Custom domains updated'))
      .catch((error: any) => {
        const message =
          error.response?.data?.custom_domains?.[0] || 'Invalid domain';
        addErrorToast({ title: message });
      });
  };

  const updateDomain = (index: number, url: string) => {
    const newDomains = [...customDomains];
    // Reset apex form to null if URL becomes invalid
    const shouldResetApex = !enterprise || !isValidUrl(url);
    newDomains[index] = {
      ...newDomains[index],
      url,
      apex_form: shouldResetApex ? null : newDomains[index].apex_form
    };
    setCustomDomains(newDomains);
  };

  const updateApexForm = (index: number, value: string) => {
    const newDomains = [...customDomains];
    newDomains[index] = {
      ...newDomains[index],
      apex_form: value || null
    };
    setCustomDomains(newDomains);
  };

  const renderDomainInputs = () => {
    return customDomains.map((domain, index) => (
      <div key={index} className='grid grid-cols-2 gap-4 mb-1'>
        <TextField
          value={domain.url}
          onChange={(val: string) => updateDomain(index, val)}
          placeholder='https://company.com'
          disabled={!enterprise}
        />
        <div className='flex gap-2'>
          <DropdownField
            disabled={!enterprise || !isValidUrl(domain.url)}
            onChange={(event) => updateApexForm(index, event.target.value)}
            selected={domain.apex_form || ''}
            options={formOptions}
          />
          <Button
            variant='ghost'
            size='icon'
            onClick={() => {
              const newDomains = customDomains.filter((_, i) => i !== index);
              if (newDomains.length === 0) {
                newDomains.push({ url: '', apex_form: null });
              }
              setCustomDomains(newDomains);
            }}
            disabled={!enterprise}
          >
            <TrashIcon className='text-current h-5 w-5' />
          </Button>
        </div>
      </div>
    ));
  };

  const renderBrandingSwitch = () => {
    const switch_ = (
      <YesNoSwitch
        id='form-badge'
        checked={freeTier || showBrand}
        onCheckedChange={(value) => {
          setShowBrand(value);
          editOrganization({ show_brand: value }).then(() =>
            addInfoToast('Settings updated')
          );
        }}
        disabled={freeTier}
      />
    );

    return freeTier ? (
      <Tooltip content='You must be on a paid plan to turn off the badge'>
        <div>{switch_}</div>
      </Tooltip>
    ) : (
      switch_
    );
  };

  const renderSubdomainSection = () => {
    const section = (
      <div className={styles.domainsContainer}>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <label className={styles.settingsLabel}>
              Custom Subdomain
              <InlineTooltip text='Your forms will be accessible at <custom-subdomain>.feathery.io/to/<slug>' />
            </label>
            <TextField
              className='mt-[10px]'
              disabled={freeTier || REGION}
              maxLength={32}
              value={customSubdomain}
              onChange={(val: string) => {
                const newValue = val.toLowerCase();
                setCustomSubdomain(newValue);
                if (!newValue) {
                  setSubdomainApexForm('');
                }
              }}
              placeholder='your-subdomain'
            />
          </div>
          <div>
            <label className={styles.settingsLabel}>
              Apex Form
              <InlineTooltip text='This form will be shown at the root of your subdomain' />
            </label>
            <DropdownField
              className='mt-[10px]'
              disabled={!subdomainApexEnabled}
              onChange={(event) => setSubdomainApexForm(event.target.value)}
              selected={subdomainApexForm}
              options={formOptions}
            />
          </div>
        </div>
        <div>
          <Button
            onClick={handleSaveSubdomain}
            disabled={freeTier}
            className='mt-4'
          >
            Save
          </Button>
        </div>
      </div>
    );

    return freeTier ? (
      <Tooltip content='You must be on a paid plan to set a custom subdomain for your forms'>
        {section}
      </Tooltip>
    ) : (
      section
    );
  };

  const renderCustomDomains = () => {
    const section = (
      <div className={styles.domainsContainer}>
        <div className='grid grid-cols-2 gap-4 mb-4'>
          <label className={styles.settingsLabel}>
            Custom Domains
            <InlineTooltip
              style={{ cursor: 'pointer' }}
              onClick={() =>
                openTab(
                  'https://docs.feathery.io/platform/launch-forms/custom-urls-and-seo#custom-dns-domain'
                )
              }
            />
          </label>
          <label className={styles.settingsLabel}>
            Apex Form
            <InlineTooltip text='This form will be shown at the root of your custom domain' />
          </label>
        </div>
        {renderDomainInputs()}
        <div className='flex gap-4 mt-4'>
          <Button
            variant='outline'
            onClick={() =>
              setCustomDomains([...customDomains, { url: '', apex_form: null }])
            }
            disabled={!enterprise}
          >
            Add Domain
          </Button>
          <Button onClick={handleSaveCustomDomains} disabled={!enterprise}>
            Save
          </Button>
        </div>
      </div>
    );

    return !enterprise ? (
      <Tooltip content="You don't have custom DNS domains enabled for your account">
        {section}
      </Tooltip>
    ) : (
      section
    );
  };

  return (
    <>
      <div className={styles.sectionSeparator} />
      <div className={styles.lowerSection}>
        <label className={styles.settingsLabel}>
          Show&nbsp;<b>Forms by Feathery</b>&nbsp;Badge
        </label>
        <div className={styles.teamFieldSection}>{renderBrandingSwitch()}</div>
        <div className={styles.teamFieldSection}>
          {renderSubdomainSection()}
        </div>
        <div className={styles.teamFieldSection}>{renderCustomDomains()}</div>
        <FaviconUpload />
      </div>
    </>
  );
}
