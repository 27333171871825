import { memo, useMemo, useState } from 'react';

import useFeatheryRedux from '../../redux';
import Dialog from '../Dialog';
import { Neutral, Positive } from '../Core/Button';
import { useAppSelector } from '../../hooks';
import { FolderIcon } from '../Icons';
import { DropdownField } from '../Core';

function MoveFormModal({ show, setShow, formId, name }: any) {
  const sessionFolders =
    useAppSelector((state) => state.folders.sessionFolders['Form']) || [];

  const {
    editPanel,
    toasts: { addToast }
  } = useFeatheryRedux();

  const panelsData = useAppSelector((state) => state.panels.panels);
  const panel = panelsData[formId];

  const [folder, setFolder] = useState(panel?.folder);

  const folderOptions = useMemo(() => {
    const folders = new Set(sessionFolders);
    for (const panel of Object.values(panelsData)) {
      if (panel.folder) {
        folders.add(panel.folder);
      }
    }
    folders.add(''); // Add 'root' folder option
    folders.add(folder); // Add current folder option
    return [...folders].map((folder) => ({
      display: folder || 'No Folder',
      value: folder
    }));
  }, [panelsData, folder, sessionFolders]);

  const performMove = async () => {
    await editPanel({ panelId: panel.id, folder: folder });
    addToast({
      icon: <FolderIcon />,
      text: (
        <>
          <strong>{panel.key}</strong> was moved to{' '}
          <strong>{folder || 'No Folder'}</strong>.
        </>
      )
    });
    setShow(false);
  };

  return (
    <Dialog
      isOpen={show}
      onClose={() => setShow(false)}
      shouldShowCloseIcon={false}
      title={`Move form to folder`}
      alignCenter
      style={{ padding: '24px 40px' }}
    >
      <div style={{ textAlign: 'left', paddingBlock: 24 }}>
        <p style={{ paddingBottom: 6 }}>
          Pick folder to move <strong>{name}</strong> to:
        </p>
        <DropdownField
          onChange={(event: any) => {
            setFolder(event.target.value);
          }}
          selected={folder}
          options={folderOptions}
        />
      </div>
      <div className='dialog-form-action'>
        <Neutral onClick={() => setShow(false)}>Cancel</Neutral>
        <Positive
          onClick={(event) => {
            event.preventDefault();
            return performMove();
          }}
        >
          Move Form
        </Positive>
      </div>
    </Dialog>
  );
}

export default memo(MoveFormModal);
