import { memo, useMemo } from 'react';
import useFeatheryRedux from '../../../redux';

import { v4 as uuidv4 } from 'uuid';

import { TrashIcon } from '../../Icons';
import {
  CheckboxField,
  DropdownField,
  DropdownMultiField,
  InlineTooltip,
  TextField
} from '../../Core';
import styles from './styles.module.scss';
import Label from '../../Dialog/Label';
import produce from 'immer';
import { useAppSelector } from '../../../hooks';
import { useParams } from 'react-router-dom';
import { Chip } from '../../Core/Chip';

export const defaultCollaborator = () => ({
  allowed: 'all',
  fields: [],
  id: uuidv4(),
  label: 'Collaborator'
});

interface CollaboratorConfigProps {
  inFormBuilder?: boolean;
  anonymousCollaboration: boolean;
  setAnonymousCollaboration: (value: boolean) => void;
  nonCollaborationDisabled: boolean;
  setNonCollaborationDisabled: (value: boolean) => void;
  collaboratorTemplate: any[];
  setCollaboratorTemplate: (value: any[]) => void;
  collaboratorOrdered: boolean;
  setCollaboratorOrdered: (value: boolean) => void;
}

function CollaboratorConfig({
  inFormBuilder = false,
  anonymousCollaboration,
  setAnonymousCollaboration,
  nonCollaborationDisabled,
  setNonCollaborationDisabled,
  collaboratorTemplate,
  setCollaboratorTemplate,
  collaboratorOrdered,
  setCollaboratorOrdered
}: CollaboratorConfigProps) {
  const { formId } = useParams<{ formId: string }>();

  const {
    toasts: { addInfoToast }
  } = useFeatheryRedux();

  const workingSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );

  const servars = useAppSelector((state) => state.fields.servars);
  const usage = useAppSelector((state) => state.fields.usage);

  const allowEditTemplate = useAppSelector((state) => {
    const account = state.accounts.account;
    return (
      account.role === 'admin' || account.permission_edit_collaborator_template
    );
  });

  const collaborationFeatureOn = useAppSelector(
    (state) => state.accounts.organization?.enterprise_features.collaboration
  );

  const fieldOptions = useMemo(() => {
    const options: Record<string, string> = {};
    if (inFormBuilder) {
      Object.values(workingSteps).forEach((step: any) => {
        step.servar_fields.forEach((field: any) => {
          const servar = field.servar;
          options[servar.id] = servar.key;
        });
      });
    } else {
      const servarIdsInForm = Object.entries(usage)
        .filter(([, usages]: [string, any]) =>
          usages.find((u: any) => u.panel_id === formId)
        )
        .map(([servarId]) => servarId);
      (servars ?? [])
        .filter((s) => servarIdsInForm.includes(s.id))
        .forEach((servar) => {
          options[servar.id] = servar.key;
        });
    }
    return Object.entries(options).map(([value, label]) => ({ label, value }));
  }, [inFormBuilder, workingSteps, formId, servars, usage]);

  const updateCollaborator = (index: number, key: string, val: any) => {
    if (!allowEditTemplate) return;
    if (!collaborationFeatureOn) {
      addInfoToast(
        'Reach out to sales about configuring collaborator permissions'
      );
      return;
    }
    const newCollaborators = produce(collaboratorTemplate, (draft) => {
      draft[index][key] = val;
    });
    setCollaboratorTemplate(newCollaborators);
  };
  const removeCollaborator = (index: number) => {
    if (!allowEditTemplate || collaboratorTemplate.length === 1) return;
    const newCollaborators = produce(collaboratorTemplate, (draft) => {
      draft.splice(index, 1);
    });
    setCollaboratorTemplate(newCollaborators);
  };
  const addCollaborator = () => {
    if (!allowEditTemplate) return;
    if (!collaborationFeatureOn) {
      addInfoToast(
        'Reach out to sales about inviting multiple people to collaborate on the same submission'
      );
      return;
    }
    setCollaboratorTemplate([...collaboratorTemplate, defaultCollaborator()]);
  };

  return (
    <>
      {collaboratorTemplate.map((collaborator, index) => (
        <div key={index} className={styles.collaboratorSection}>
          <div className={styles.header}>
            <Label className={styles.label}>
              {collaboratorOrdered ? `${index + 1}. ` : ''}Collaborator
            </Label>
            <div
              className={styles.delete}
              onClick={() => removeCollaborator(index)}
            >
              <TrashIcon width={16} height={16} />
            </div>
          </div>
          <div className={styles.controlsSection}>
            <div className={styles.controlLabel}>ID</div>
            <div style={{ width: '100%' }}>
              <Chip label={collaborator.id} />
            </div>
          </div>
          <div className={styles.controlsSection}>
            <div className={styles.controlLabel}>Role</div>
            <TextField
              placeholder={`Collaborator ${index + 1}`}
              disabled={!allowEditTemplate}
              className={styles.controlField}
              onChange={(newVal: string) =>
                updateCollaborator(index, 'label', newVal)
              }
              value={collaborator.label}
            />
          </div>
          <div className={styles.controlsSection}>
            <div className={styles.controlLabel}>Can Edit</div>
            <DropdownField
              disabled={!allowEditTemplate}
              className={styles.controlField}
              onChange={(event: any) =>
                updateCollaborator(index, 'allowed', event.target.value)
              }
              selected={collaborator.allowed}
              options={[
                { value: 'all', display: 'All Fields' },
                { value: 'custom', display: 'Custom Fields' }
              ]}
            />
          </div>
          {collaborator.allowed === 'custom' && (
            <div className={styles.controlsSection}>
              <div className={styles.controlLabel}>Custom Fields</div>
              <DropdownMultiField
                disabled={!allowEditTemplate}
                className={styles.controlField}
                options={fieldOptions}
                selected={collaborator.fields}
                required
                placeholder='Select Editable Fields'
                onChange={(items: { value: string }[]) => {
                  updateCollaborator(
                    index,
                    'fields',
                    items.map((item) => item.value)
                  );
                }}
              />
            </div>
          )}
        </div>
      ))}
      <div
        onClick={() => addCollaborator()}
        className={styles.addCollaboratorButton}
      >
        Add Collaborator
      </div>
      <CheckboxField
        checked={collaboratorOrdered}
        onChange={setCollaboratorOrdered}
        text='Set Collaborator Order'
        style={{ marginTop: '10px' }}
      />
      <CheckboxField
        checked={anonymousCollaboration}
        onChange={setAnonymousCollaboration}
        disabled={nonCollaborationDisabled}
        text={
          <>
            Allow Anonymous Starts
            <InlineTooltip text='Anyone can start a collaborative submission from the form directly.' />
          </>
        }
        style={{ marginTop: '10px' }}
      />
      <CheckboxField
        checked={nonCollaborationDisabled}
        onChange={setNonCollaborationDisabled}
        disabled={anonymousCollaboration}
        text={
          <>
            Disable Regular Submissions
            <InlineTooltip text='Disable direct access to the form and only allow access through a collaborative submission' />
          </>
        }
        style={{ marginTop: '10px' }}
      />
    </>
  );
}

export default memo(CollaboratorConfig);
