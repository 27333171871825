import { useCallback, useState } from 'react';
import useFeatheryRedux from '../redux';
import { FolderIcon } from '../components/Icons';
import { ReactNode } from 'react';

interface Item {
  id: string;
  folder: string;
}

interface EditProps {
  folder: string;
  [payloadKey: string]: string;
}

interface Toast {
  icon?: ReactNode;
  text: ReactNode;
  type?: 'error' | 'success' | 'info' | 'warning';
}

interface FolderOperationResult {
  success: boolean;
  error?: Error;
}

interface UseFolderReturn<T> {
  itemToMove: T | null;
  setItemToMove: (item: T | null) => void;
  itemToDelete: T | null;
  setItemToDelete: (item: T | null) => void;
  createFolder: string | null;
  setCreateFolder: (folder: string | null) => void;
  performBulkMove: (
    sourceFolder: string,
    targetFolder?: string
  ) => Promise<FolderOperationResult>;
  isProcessing: boolean;
}

export function useFolder<T extends Item>(
  items: Record<string, T>,
  edit: (payload: EditProps) => Promise<unknown>,
  folderKey: string,
  payloadKey: string
): UseFolderReturn<T> {
  const [itemToMove, setItemToMove] = useState<T | null>(null);
  const [itemToDelete, setItemToDelete] = useState<T | null>(null);
  const [createFolder, setCreateFolder] = useState<string | null>('');
  const [isProcessing, setIsProcessing] = useState(false);

  const {
    toasts: { addToast },
    folders: { removeFolder, addFolder }
  } = useFeatheryRedux();

  const showToast = useCallback(
    (toast: Toast) => {
      addToast({
        ...toast,
        type: toast.type || 'success'
      });
    },
    [addToast]
  );

  const validateFolderName = useCallback((folderName: string): boolean => {
    return folderName.trim().length > 0 && !/[<>:"/\\|?*]/.test(folderName);
  }, []);

  const performBulkMove = useCallback(
    async (
      sourceFolder: string,
      targetFolder = ''
    ): Promise<FolderOperationResult> => {
      if (isProcessing) {
        return {
          success: false,
          error: new Error('Operation already in progress')
        };
      }

      const action = targetFolder === '' ? 'Delete' : 'Rename';
      setIsProcessing(true);

      try {
        // Validate folder names
        if (targetFolder && !validateFolderName(targetFolder)) {
          throw new Error('Invalid target folder name');
        }

        const itemsToMove = Object.values(items).filter(
          (item) => item.folder === sourceFolder
        );

        // perform update by updating each item individually
        await Promise.all(
          itemsToMove.map((item) =>
            edit({ [payloadKey]: item.id, folder: targetFolder })
          )
        );

        // Update locally saved folder structure
        await removeFolder({
          name: folderKey,
          folder: sourceFolder
        });

        if (targetFolder) {
          await addFolder({
            name: folderKey,
            folder: targetFolder
          });
        }

        showToast({
          icon: <FolderIcon />,
          text: (
            <>
              {`${action}d`} {sourceFolder}
              {action === 'Rename' && <> to {targetFolder}</>}
            </>
          )
        });

        return { success: true };
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : 'Unknown error occurred';

        showToast({
          text: `Could not ${action.toLowerCase()} folder: ${errorMessage}`,
          type: 'error'
        });

        return {
          success: false,
          error: error instanceof Error ? error : new Error(errorMessage)
        };
      } finally {
        setIsProcessing(false);
      }
    },
    [
      items,
      edit,
      folderKey,
      payloadKey,
      validateFolderName,
      showToast,
      isProcessing
    ]
  );

  return {
    itemToMove,
    setItemToMove,
    itemToDelete,
    setItemToDelete,
    createFolder,
    setCreateFolder,
    performBulkMove,
    isProcessing
  };
}
