import React, { ChangeEvent, useRef, useState } from 'react';
import Dialog from '../../../Dialog';
import Label from '../../../Dialog/Label';
import { Button } from '../../../Core/Button/button';
import { Positive } from '../../../Core/Button';
import { openTab } from '../../../../utils/domOperations';
import { InfoIcon } from '../../../Icons';
import { Tooltip } from '../../../Core/Tooltip/Tooltip';

type FieldPair = {
  salesforceField: string;
  quikField: string;
};

export interface SfFieldMappingData {
  fieldsMap: { sfObject: string; fields: FieldPair[] }[];
}

interface UploadFieldMappingDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  quikConfig: any;
  setQuikConfig: (quikConfig: any) => void;
}

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
  });
};

const UploadFieldMappingDialog: React.FC<UploadFieldMappingDialogProps> = ({
  isOpen,
  setIsOpen,
  quikConfig,
  setQuikConfig
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [fieldMappingFile, setFieldMappingFile] = useState<File | null>(null);
  const [fieldMapFileName, setFieldMapFileName] = useState<string>(
    quikConfig?.sf_mapping_file_name || ''
  );
  const [error, setError] = useState('');

  const [fieldMappingData, setFieldMappingData] = useState<SfFieldMappingData>({
    fieldsMap: []
  });

  const handleCSVUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFieldMappingFile(file);
      setFieldMapFileName(file.name);
      setError('');

      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        const payload = event.target?.result as string;

        const rows = payload.split('\n').map((line) => {
          const values = [];
          let startValueIndex = 0;
          let inQuotes = false;

          for (let i = 0; i < line.length; i++) {
            if (line[i] === '"') {
              inQuotes = !inQuotes;
            } else if (line[i] === ',' && !inQuotes) {
              values.push(
                line.slice(startValueIndex, i).trim().replace(/^"|"$/g, '')
              );
              startValueIndex = i + 1;
            }
          }

          values.push(line.slice(startValueIndex).trim().replace(/^"|"$/g, ''));
          return values;
        });

        if (rows.length <= 1) {
          setError('This file has no field mapping data to parse');
          return;
        }

        const headers = rows[0].map((header) => header.trim().toLowerCase());
        const findHeaderIndex = (substring: string) =>
          headers.findIndex((header) => header.includes(substring));

        const sfObjectIndex = findHeaderIndex('salesforce object');
        const sfFieldIndex = findHeaderIndex('salesforce field');
        const quikFieldIndex = findHeaderIndex('quik field');

        if (
          sfObjectIndex === -1 ||
          sfFieldIndex === -1 ||
          quikFieldIndex === -1
        ) {
          setError(
            'CSV must contain "Salesforce Object", "Salesforce Field", and "Quik Field" columns (case-insensitive)'
          );
          return;
        }

        const fieldMap: Record<
          string,
          {
            sfObject: string;
            fields: { salesforceField: string; quikField: string }[];
          }
        > = {};

        rows.slice(1).forEach((row) => {
          const sfObject = row[sfObjectIndex]?.trim();
          const salesforceField = row[sfFieldIndex]?.trim();
          const quikField = row[quikFieldIndex]?.trim();

          if (!sfObject || !salesforceField || !quikField) {
            setError('CSV file contains row with missing data');
            return;
          }

          if (!fieldMap[sfObject]) {
            fieldMap[sfObject] = {
              sfObject,
              fields: []
            };
          }

          fieldMap[sfObject].fields.push({
            salesforceField,
            quikField
          });
        });

        setFieldMappingData({ fieldsMap: Object.values(fieldMap) });
      });

      reader.addEventListener('error', () => {
        setError('Error reading file');
      });

      reader.readAsText(file);
    }
  };

  const handleSubmit = async () => {
    const jsonBlob = new Blob([JSON.stringify(fieldMappingData)], {
      type: 'application/json'
    });

    const base64Blob = await blobToBase64(jsonBlob);

    setQuikConfig({
      ...quikConfig,
      sf_field_mapping: base64Blob,
      sf_mapping_file_name: fieldMappingFile?.name
    });

    setIsOpen(false);
  };

  return (
    <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className='text-center'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8
          }}
        >
          <h4>Upload Field Mapping CSV</h4>
          <button
            onClick={(e: any) => {
              e.preventDefault();
              openTab(
                'https://feathery-forms.webflow.io/integrations/quik#setup'
              );
            }}
            style={{
              color: 'var(--grey-70)',
              fontSize: '14px',
              fontWeight: 400,
              display: 'flex',
              gap: '4px',
              alignItems: 'center'
            }}
          >
            <Tooltip content='Click and see Automated Salesforce & Quik Field Mapping with Feathery section'>
              <div
                style={{ display: 'flex', gap: '4px', alignItems: 'center' }}
              >
                <InfoIcon width={17} height={17} />
                <>View Documentation</>
              </div>
            </Tooltip>
          </button>
        </div>
        <div
          style={{
            paddingBlockStart: 24
          }}
        >
          <Label style={{ margin: 0 }}>
            {fieldMapFileName || 'No File Selected'}
          </Label>
          {error && (
            <p
              style={{
                color: 'var(--red-border)',
                paddingTop: 3,
                margin: 0
              }}
            >
              {error}
            </p>
          )}
        </div>
        <div
          className='dialog-form-action text-center'
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            variant='outline-primary'
            onClick={() => fileInput.current?.click()}
          >
            {fieldMapFileName ? 'Choose New CSV' : 'Choose CSV'}
          </Button>
          <input
            ref={fileInput}
            type='file'
            onChange={handleCSVUpload}
            accept='.csv'
            style={{
              position: 'absolute',
              bottom: 0,
              opacity: 0,
              zIndex: -1
            }}
          />
          {fieldMappingFile && (
            <Positive
              disabled={fieldMappingData === null || Boolean(error)}
              onClick={handleSubmit}
            >
              Submit
            </Positive>
          )}
        </div>
        <div>
          <input
            ref={fileInput}
            type='file'
            onChange={handleCSVUpload}
            accept='csv'
            style={{
              position: 'absolute',
              bottom: 0,
              opacity: 0,
              zIndex: -1
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default UploadFieldMappingDialog;
