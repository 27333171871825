import { forwardRef, Ref, useState } from 'react';
import { CloseIcon } from '../Icons';
import { ignoreDrawControls } from '../RenderingEngine/Controls/utils';
import styles from './styles.module.scss';
import classNames from 'classnames';
import ReactPortal from './components/ReactPortal';
import testIds from '../../utils/testIds';
import { ConfirmWarning } from '../Modals/CreateAPIServiceModal/ConfirmWarning';

function Dialog(
  {
    className,
    children,
    size = 'sm',
    title,
    header,
    isOpen,
    onClose = () => {},
    shouldShowCloseIcon = true,
    alignCenter = false,
    style,
    visibleBackdrop = true,
    showExitConfirmation = false
  }: any,
  ref: Ref<HTMLDivElement>
) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleClose = (withWarning: boolean) => {
    if (withWarning && showExitConfirmation) {
      setShowConfirmModal(true);
    } else {
      setShowConfirmModal(false);
      onClose();
    }
  };

  return (
    <ReactPortal>
      <ConfirmWarning
        show={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={() => handleClose(false)}
      />
      <div
        className={classNames(styles.dialogWrapper, {
          [styles.show]: isOpen
        })}
      >
        {isOpen && (
          <div
            onClick={() => handleClose(true)}
            className={classNames(styles.backdrop, {
              [styles.visible]: visibleBackdrop
            })}
          />
        )}
        <div
          className={classNames(
            styles.dialogContainer,
            isOpen && styles.animate
          )}
        >
          {isOpen && (
            <div
              ref={ref}
              style={style}
              className={classNames(
                ignoreDrawControls,
                styles.dialog,
                className,
                styles[`dialog-${size}`],
                alignCenter && 'text-center',
                className
              )}
            >
              {header && header(title)}
              {!header && title && (
                <div className={styles.dialogHeader}>
                  <h2>{title}</h2>
                </div>
              )}
              {children}
              {shouldShowCloseIcon && (
                <div
                  data-testid={testIds.dialogClose}
                  className={styles.dialogClose}
                  tabIndex={0}
                  onClick={() => handleClose(true)}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </ReactPortal>
  );
}

export default forwardRef(Dialog);
