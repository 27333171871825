import {
  DropdownField,
  InlineTooltip,
  TextField,
  YesNoSwitch
} from '../../../components/Core';
import { PlusIcon, TrashIcon, UpArrowIcon } from '../../../components/Icons';
import { getDefaultEntity } from '../utils';
import type { FieldProperties } from '../ExtractionCreateModal/types';
import Entity from './Entity';
import Label from '../../../components/Dialog/Label';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Button } from '../../../components/Core/Button/button';
import useIntegrations from '../../../components/FormIntegrations/useIntegrations';
import { AI_INTEGRATION_TYPES } from '../../../components/AIIntegrations/types';
import { memo } from 'react';

type Label = {
  label: string;
  value: string;
};

type Props = {
  extractionId?: string;
  index: number;
  group: any;
  fieldProperties: Record<string, FieldProperties>;
  hiddenFieldProperties: Record<string, Partial<FieldProperties>>;
  isLast: boolean;
  file_type: string;
  onUpdate: (key: string, value: any) => void;
  onRemove: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
};

const QuestionGroup = (props: Props) => {
  const {
    extractionId,
    index,
    group,
    fieldProperties,
    hiddenFieldProperties,
    isLast,
    file_type,
    onUpdate,
    onRemove,
    onMoveUp,
    onMoveDown
  } = props;

  const integrations = useIntegrations({
    all: true,
    extractionId,
    entityType: 'ai'
  });

  const isEmailIntegActive = !!integrations[AI_INTEGRATION_TYPES.EMAIL_INBOX];

  const label = group.entities.map((entity: any) => entity.name).join(', ');
  return (
    <div
      key={`question-group-${index + 1}`}
      className={styles.questionGroupSection}
    >
      <QuestionGroupHeader
        label={label}
        index={index}
        isLast={isLast}
        onMoveUp={onMoveUp}
        onMoveDown={onMoveDown}
        onRemove={onRemove}
      />
      {file_type === 'image' ? (
        <DocumentQuestionGroup
          group={group}
          onUpdate={onUpdate}
          isEmailIntegActive={isEmailIntegActive}
          index={index}
          fieldProperties={fieldProperties}
          hiddenFieldProperties={hiddenFieldProperties}
        />
      ) : (
        <CSVQuestionGroup
          group={group}
          onUpdate={onUpdate}
          index={index}
          fieldProperties={fieldProperties}
          hiddenFieldProperties={hiddenFieldProperties}
        />
      )}
    </div>
  );
};

export default memo(QuestionGroup, (prev, next) => {
  return (
    prev.extractionId === next.extractionId &&
    prev.index === next.index &&
    prev.group === next.group &&
    prev.fieldProperties === next.fieldProperties &&
    prev.hiddenFieldProperties === next.hiddenFieldProperties &&
    prev.isLast === next.isLast &&
    prev.file_type === next.file_type
  );
});

function QuestionGroupHeader({
  label,
  index,
  isLast,
  onMoveUp,
  onMoveDown,
  onRemove
}: any) {
  return (
    <div className={styles.header}>
      <div className={styles.labelContainer}>
        <Label className={styles.label}>{label || `Query ${index + 1}`}</Label>
        <InlineTooltip text='The query will be used by AI to find answer(s) from each document, and the answer(s) will be saved into the field(s)' />
      </div>
      <div className={styles.actionContainer}>
        {index !== 0 && (
          <div
            className={classNames(styles.actionButton, styles.action)}
            onClick={onMoveUp}
          >
            <UpArrowIcon width={16} height={16} className={styles.fill} />
          </div>
        )}
        {!isLast && (
          <div
            className={classNames(styles.actionButton, styles.action)}
            onClick={onMoveDown}
          >
            <UpArrowIcon
              className={classNames(styles.flip, styles.fill)}
              width={16}
              height={16}
            />
          </div>
        )}
        <div
          className={classNames(styles.actionButton, styles.action)}
          onClick={onRemove}
        >
          <TrashIcon width={16} height={16} />
        </div>
      </div>
    </div>
  );
}
function CSVQuestionGroup({
  group,
  onUpdate,
  index,
  fieldProperties,
  hiddenFieldProperties
}: any) {
  return (
    <div className={classNames(styles.keyValuePairs, styles.moreGap)}>
      <div className={styles.keyValuePair}>
        <div className={styles.key} style={{ width: '75px' }}>
          Type{' '}
          <InlineTooltip text='What type of extraction do you want to run?' />
        </div>
        <div className={styles.value}>
          <DropdownField
            className={styles.controlField}
            selected={group.question_type}
            onChange={(event: any) =>
              onUpdate('question_type', event.target.value)
            }
            options={[
              { value: 'cell_value', display: 'Cell' },
              {
                value: 'column_value',
                display: 'Column'
              }
            ]}
            required
          />
        </div>
      </div>
      <div className={styles.keyValuePair}>
        <div className={styles.entitiesContainer}>
          {group.entities?.map((entity: any, entityIndex: number) => (
            <Entity
              key={`group-${index}-entity-${entityIndex + 1}`}
              type={group.question_type}
              labels={{
                name: group.question_type === 'cell_value' ? 'Cell' : 'Column',
                details:
                  group.question_type === 'cell_value' ? 'Sheet' : 'Details'
              }}
              helpers={{
                name:
                  group.question_type === 'cell_value'
                    ? 'The exact column and row index of the cell'
                    : 'A description of the column header. Will use AI to match',
                details:
                  group.question_type === 'cell_value'
                    ? '(Optional) The exact sheet name to pull from. Defaults to the first sheet'
                    : ''
              }}
              placeholders={{
                name:
                  group.question_type === 'cell_value'
                    ? 'A3'
                    : 'Employee State',
                details: group.question_type === 'cell_value' ? 'Sheet1' : ''
              }}
              entity={entity}
              fieldProperties={fieldProperties}
              hiddenFieldProperties={hiddenFieldProperties}
              onUpdate={(key: string | object, value?: any) => {
                const updatedEntities = [...group.entities];
                updatedEntities[entityIndex] =
                  typeof key !== 'string'
                    ? {
                        ...entity,
                        ...key
                      }
                    : {
                        ...entity,
                        [key]: value
                      };
                onUpdate('entities', updatedEntities);
              }}
              onRemove={() => {
                const updatedEntities = [...group.entities];
                updatedEntities.splice(entityIndex, 1);
                if (updatedEntities.length === 0) {
                  updatedEntities.push(getDefaultEntity());
                }
                onUpdate('entities', updatedEntities);
              }}
              details={group.question_type === 'cell_value'}
              advanced={false}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function DocumentQuestionGroup({
  group,
  onUpdate,
  isEmailIntegActive,
  index,
  fieldProperties,
  hiddenFieldProperties
}: any) {
  return (
    <div className={classNames(styles.keyValuePairs, styles.moreGap)}>
      <div className={styles.keyValuePair}>
        <div className={styles.key} style={{ width: '75px' }}>
          Type{' '}
          <InlineTooltip text='What type of extraction do you want to run?' />
        </div>
        <div className={styles.value}>
          <DropdownField
            className={styles.controlField}
            selected={group.question_type}
            onChange={(event: any) =>
              onUpdate('question_type', event.target.value)
            }
            options={[
              { value: 'one_value', display: 'Single Value' },
              {
                value: 'multiple_value',
                display: 'Multiple Values'
              },
              {
                value: 'page_number',
                display: 'Page Numbers'
              }
            ]}
            required
          />
        </div>
      </div>
      {!['one_value', 'page_number'].includes(group.question_type) && (
        <div className={styles.keyValuePair}>
          <div className={styles.key} style={{ width: '150px' }}>
            Query Details{' '}
            <InlineTooltip text='(Optional) Description & constraints for the overall query' />
          </div>
          <div className={styles.value}>
            <TextField
              placeholder='Only return active account assets'
              className={styles.controlField}
              onComplete={(newVal: string) => {
                onUpdate('criteria', newVal);
              }}
              value={group.criteria}
            />
          </div>
        </div>
      )}
      {isEmailIntegActive && (
        <div
          className={styles.keyValuePair}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className={styles.key} style={{ width: '134px' }}>
            Run on Email{' '}
            <InlineTooltip text='Run the query on email subject and body before running it on the attachments found within the email. If a value is found on the email, this query will not run on the attachments.' />
          </div>
          <div className={styles.value}>
            <YesNoSwitch
              checked={group.run_email}
              onCheckedChange={(value: boolean) => {
                onUpdate('run_email', value);
              }}
            />
          </div>
        </div>
      )}
      <div className={styles.keyValuePair}>
        <div className={styles.entitiesContainer}>
          {group.entities?.map((entity: any, entityIndex: number) => (
            <Entity
              key={`group-${index}-entity-${entityIndex + 1}`}
              type={group.question_type}
              labels={{
                name: group.question_type === 'page_number' ? 'Name' : 'Entity'
              }}
              helpers={{
                name:
                  group.question_type === 'page_number'
                    ? 'The name of the page number query'
                    : 'The name of the variable you want to extract',
                details:
                  group.question_type === 'page_number'
                    ? 'Details that the page must match to return the page number'
                    : '(Optional) Additional entity details to tune the extraction'
              }}
              placeholders={
                group.question_type === 'page_number'
                  ? {
                      name: 'Active Holding Pages',
                      details: 'Pages that include active holdings'
                    }
                  : undefined
              }
              entity={entity}
              fieldProperties={fieldProperties}
              hiddenFieldProperties={hiddenFieldProperties}
              advanced={group.question_type !== 'page_number'}
              onUpdate={(key: string | object, value?: any) => {
                const updatedEntities = [...group.entities];
                updatedEntities[entityIndex] =
                  typeof key !== 'string'
                    ? {
                        ...entity,
                        ...key
                      }
                    : {
                        ...entity,
                        [key]: value
                      };
                onUpdate('entities', updatedEntities);
              }}
              onRemove={() => {
                const updatedEntities = [...group.entities];
                updatedEntities.splice(entityIndex, 1);
                if (updatedEntities.length === 0) {
                  updatedEntities.push(getDefaultEntity());
                }
                onUpdate('entities', updatedEntities);
              }}
            />
          ))}
        </div>
      </div>
      {group.question_type === 'multiple_value' && (
        <div className={styles.keyValuePair}>
          <Button
            variant='dashed'
            onClick={(e) => {
              e.preventDefault();
              onUpdate('entities', [
                ...(group.entities || []),
                getDefaultEntity()
              ]);
            }}
          >
            <PlusIcon className={styles.icon} width={12} />
            <span>Add Entity</span>
          </Button>
        </div>
      )}
    </div>
  );
}
