import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AIExtractionRun } from './types';
import { parseRawRunData } from './utils';
import { useAppSelector } from '../../hooks';
import AIExtractionPanel from './components/AIExtractionPanel';
import EmailViewer from './components/EmailViewer';
import PDFViewer from './components/PDFViewer';
import ExtractionProvider from './context';
import Zoomable from './components/Zoomable';
import classNames from 'classnames';
import styles from './styles.module.scss';
import useFeatheryRedux from '../../redux';
import AINavigation from '../../components/NavBar/AINavigation';
import BackgroundControls from './components/BackgroundControls';
import ErrorCard from '../../components/ErrorCard';

type URLParams = {
  extractionId: string;
  runId: string;
};

export default function AIExtractionRunPage() {
  const { runId } = useParams<URLParams>();

  const run = useAppSelector((state) => state.ai.runDetails);
  const [parsedRun, setParsedRun] = useState<AIExtractionRun | undefined>();

  const { getAIExtractionRun } = useFeatheryRedux();

  useEffect(() => {
    getAIExtractionRun({ runId });
    document.body.style.overflow = 'hidden'; // Disable overflow on body for Extraction Run page only
  }, []);

  useEffect(() => {
    if (!run || !run.id || run.id !== runId) return;
    setParsedRun(parseRawRunData(run));
  }, [run]);

  return (
    <>
      <AINavigation activeItem='results' />
      {parsedRun ? (
        <ExtractionProvider>
          <BackgroundControls>
            <div
              className={classNames(
                'background-controls',
                styles.pageContainer
              )}
            >
              <div className={styles.pageSidebar}>
                <div className={styles.pageSidebarContent}>
                  <AIExtractionPanel run={parsedRun} />
                </div>
                <div className={styles.pageSidebarPlaceholder} />
              </div>
              <div
                className={classNames(styles.pageContent, styles.centerContent)}
              >
                <Zoomable defaultMarginTop={20}>
                  <div className={styles.viewer}>
                    {parsedRun.emailContent && (
                      <>
                        <div className={styles.viewerTitle}>Scanned Email</div>
                        <EmailViewer run={parsedRun} />
                        {parsedRun.displayPDF && (
                          <div className={styles.viewerTitle}>
                            Scanned Document
                          </div>
                        )}
                      </>
                    )}
                    {parsedRun.displayPDF && <PDFViewer run={parsedRun} />}
                    {!parsedRun.success && (
                      <>
                        <div className={styles.dataLayerPushdown}></div>
                        <div className={styles.dataLayerError}>
                          <div className={styles.content}>
                            <ErrorCard
                              className={styles.dataLayerErrorCard}
                              error={
                                parsedRun.errorMessage ||
                                "The extraction ran into an error while processing the user's document."
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Zoomable>
              </div>
            </div>
          </BackgroundControls>
        </ExtractionProvider>
      ) : null}
    </>
  );
}
