import { memo, useEffect, useMemo, useState } from 'react';

import '../../style/table-view.scss';
import { ChevronDownIcon } from '../Icons';
import classNames from 'classnames';
import CheckBoxIcon from '../Icons/system/CheckBoxIcon';
import useFeatheryRedux from '../../redux';
import { useAppSelector } from '../../hooks';
import testIds from '../../utils/testIds';
import { TableActions } from './Actions';
import { getTableActions } from './utils';

function Table({
  data,
  name = 'Record',
  columns = [],
  initSelectId = '',
  searchStr = '',
  sortable = true,
  defaultSort = { order: 0 },
  filterCallback = null,
  sortCallback = null,
  showSelected = false,
  hasOverflowMenu = false,
  onBeforeSelect = (data: any) => true,
  onSelect = null,
  onDownload = null,
  downloadName = '',
  onDelete = null,
  onEdit = null,
  onShare = null,
  onCopy = null,
  onMoveToFolder = null,
  idKey = 'id',
  type = 'fullPage',
  hideEmptyText = false,
  className
}: any) {
  const [selectedId, setSelectedId] = useState(initSelectId);

  const sort =
    useAppSelector((state) => state.tables.sortOrders[name]) || defaultSort;
  const {
    tables: { updateSort }
  } = useFeatheryRedux();

  useEffect(() => setSelectedId(initSelectId), [initSelectId]);

  const viewData = useMemo(() => {
    let viewData = [...data];
    if (searchStr && filterCallback)
      viewData = viewData.filter((o) => filterCallback(searchStr, o));

    if (!sortCallback && sort.key && !sort.noSort && viewData.length > 0) {
      let sortFn;
      const sortKey = sort.sortBy || sort.key;
      const val = viewData[0][sortKey];
      if (!isNaN(val)) {
        sortFn = (o1: any, o2: any) =>
          sort.order * (Number(o1[sortKey]) - Number(o2[sortKey]));
      } else {
        sortFn = (o1: any, o2: any) =>
          sort.order * (o1[sortKey] ?? '').localeCompare(o2[sortKey]);
      }
      viewData.sort(sortFn);
    }

    return viewData;
  }, [data, searchStr, sort, filterCallback, sortCallback]);

  const handleClick = (data: any) => () => {
    if (onBeforeSelect(data)) {
      setSelectedId(selectedId === data[idKey] ? '' : data[idKey]);
      if (onSelect) onSelect(data);
    }
  };

  const handleOrder = (col: any) => () => {
    if (!sortable || col.noSort) return;

    const sortCb = (order: any, key: any, sortByKey: any) => {
      if (sortCallback) sortCallback(order, key, sortByKey);
    };

    if (sort.key === col.key) {
      if (sort.order === -1) {
        sortCb(1, col.key, col.sortBy);
        updateSort({ name, sort: { ...col, order: 1 } });
      } else {
        sortCb(0, '', '');
        updateSort({ name, sort: { order: 0 } });
      }
    } else {
      sortCb(-1, col.key, col.sortBy);
      updateSort({ name, sort: { ...col, order: -1 } });
    }
  };

  const tableActions = getTableActions({
    onShare,
    onCopy,
    onDownload,
    downloadName,
    onMoveToFolder,
    onDelete,
    onEdit
  });

  let recordLabel = name.toLowerCase();
  if (!recordLabel.endsWith('s')) recordLabel += 's';

  return (
    <div
      className={classNames(
        'list-view-content',
        `list-view-${type}`,
        className
      )}
    >
      <table className='list-view-table'>
        <thead>
          <tr>
            {showSelected && <th className='select-cell' />}
            {/* @ts-expect-error TS(7006) FIXME: Parameter 'col' implicitly has an 'any' type. */}
            {columns.map((col) => (
              <th
                key={col.key}
                tabIndex={0}
                onClick={handleOrder(col)}
                style={{ ...col.headerCellStyle }}
                // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                className={
                  sort.key && sort.key === col.key
                    ? 'list-view-table-sorted-by'
                    : null
                }
              >
                {col.name}
                {sortable && !col.noSort && (
                  <ChevronDownIcon
                    width={14}
                    height={14}
                    className={
                      sort.key === col.key && sort.order === 1 ? 'flip' : ''
                    }
                  />
                )}
              </th>
            ))}
            {tableActions.length > 0 && <th className='icon-col' />}
          </tr>
        </thead>
        <tbody>
          {viewData.map((entry, i) => (
            <tr
              data-testid={testIds.tableRow(i)}
              id={entry[idKey]}
              key={entry[idKey]}
              onClick={handleClick(entry)}
              className={classNames(
                i % 2 === 1 && 'dark-row',
                onSelect && 'selectable'
              )}
            >
              {showSelected && (
                <td className='select-cell'>
                  {entry[idKey] === selectedId && <CheckBoxIcon />}
                </td>
              )}
              {/* @ts-expect-error TS(7006) FIXME: Parameter 'col' implicitly has an 'any' type. */}
              {columns.map((col) => (
                <td
                  key={`${i}-${col.key}`}
                  style={{ ...col.cellStyle }}
                  className='data-cell'
                >
                  {col.renderCell && col.renderCell(entry)}
                  {!col.renderCell && entry[col.key] !== null && entry[col.key]}
                  {!col.renderCell && entry[col.key] === null && 'None'}
                </td>
              ))}
              {tableActions.length > 0 && (
                <td key='icons' className='icons'>
                  <TableActions
                    entry={entry}
                    actions={tableActions}
                    hasOverflowMenu={hasOverflowMenu}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {viewData.length === 0 && !hideEmptyText && (
        <span className='list-view-no-data'>
          {searchStr
            ? `No results for ${searchStr.trim()}`
            : `No ${recordLabel} yet`}
        </span>
      )}
    </div>
  );
}

export default memo(Table);
