import '../../style/dialog-form.css';

import { useState } from 'react';
import { TextField } from '../Core';
import { FieldSelectorWithModal } from '../Modals';
import Dialog from '../Dialog';
import { Negative, Neutral, Positive } from '../Core/Button';
import useFeatheryRedux from '../../redux';

export default function ConditionAttachmentModal({
  show,
  closeModal,
  saveRule,
  rule: _rule
}: any) {
  const {
    toasts: { addToast, addErrorToast }
  } = useFeatheryRedux();

  const [rule, setRule] = useState(_rule ?? {});

  const removeCondition = async () => {
    saveRule(null);
    addToast({
      text: 'Attachment condition removed'
    });
    closeModal();
  };

  const setCondition = async () => {
    rule.value = (rule.value || '').trim();
    if (!rule.field_id || !rule.field_type) {
      addErrorToast({
        title: 'Missing field for condition!'
      });
      return;
    }
    if (!rule.value) {
      addErrorToast({
        title: 'Missing value for condition!'
      });
      return;
    }
    saveRule(rule);
    addToast({
      text: 'Added condition to attachment'
    });
    closeModal();
  };

  return (
    <Dialog isOpen={show} onClose={closeModal} title='Attach Conditionally'>
      <p className='text-left py-2'>Only include attachment if</p>
      <div className='flex items-center'>
        <FieldSelectorWithModal
          selectId={rule.field_id}
          selectType={rule.field_type}
          onSelect={(data: any) => {
            const field_type = data.selectType;
            const field_id = data.selectId;
            setRule({
              ...rule,
              field_type,
              field_id
            });
          }}
          excludeServarTypes={['file_upload', 'signature'] as any}
          style={{ minWidth: '130px' }}
        />
        =
        <TextField
          placeholder='Value'
          value={rule.value}
          onComplete={(val: any) => {
            setRule({ ...rule, value: val });
          }}
          triggerCleanUp
          style={{ marginLeft: '5px' }}
        />
      </div>
      <div className='dialog-form-action flex items-center justify-between -mb-1'>
        <div className='flex items-center gap-3'>
          {Object.keys(_rule).length > 0 && (
            <Negative
              size='sm'
              onClick={(event) => {
                event.preventDefault();
                return removeCondition();
              }}
            >
              Remove Condition
            </Negative>
          )}
          <Neutral size='sm' onClick={closeModal}>
            Cancel
          </Neutral>
        </div>
        <Positive
          size='sm'
          onClick={(event) => {
            event.preventDefault();
            return setCondition();
          }}
        >
          Save
        </Positive>
      </div>
    </Dialog>
  );
}
