import styles from './styles.module.scss';
import pageStyles from '../styles.module.scss';
import HeaderFilterResultsTable from '../../components/HeaderFilterResultsTable';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { useMemo, useState } from 'react';
import { formatDate } from '../../utils/format';
import classNames from 'classnames';
import ExtractionCreateModal from './ExtractionCreateModal';
import DeleteExtractionModal from '../../components/Modals/DeleteExtractionModal';
import useFeatheryRedux from '../../redux';

export default function AIExtractionListPage() {
  const history = useHistory();

  const { createAIExtraction } = useFeatheryRedux();

  const extractions = useAppSelector((state) => state.ai.extractions);
  const servars = useAppSelector((state) => state.fields.servars ?? []);
  const hiddenFields = useAppSelector(
    (state) => state.fields.hiddenFields ?? []
  );
  const [create, setCreate] = useState(false);
  const [extractionToDelete, setExtractionToDelete] = useState<any>(null);

  const dataToRender = useMemo(() => {
    return extractions.map((extraction: any) => {
      return {
        ...extraction,
        updated_at_pretty: formatDate(extraction.updated_at),
        sources: extraction.file_sources
          .map((source: string) => {
            const s = servars.find((servar) => servar.id === source);
            if (s) return s.key;
            else return hiddenFields.find((hf) => hf.id === source)?.key ?? '';
          })
          .join(', ')
      };
    });
  }, [extractions]);

  return (
    <>
      <ExtractionCreateModal isOpen={create} close={() => setCreate(false)} />
      <DeleteExtractionModal
        close={() => setExtractionToDelete(null)}
        show={Boolean(extractionToDelete)}
        extractionId={extractionToDelete?.id}
        name={extractionToDelete?.key}
      />
      <div className={styles.aiExtractionsListPage}>
        <div className={pageStyles.pageHeadRow}>
          <h1 className={classNames('page-head', pageStyles.pageHeadTitle)}>
            Document Extractions
          </h1>
        </div>
        <HeaderFilterResultsTable
          data={dataToRender}
          columns={[
            { key: 'key', name: 'Name' },
            { key: 'sources', name: 'Files to Extract' },
            {
              key: 'updated_at_pretty',
              name: 'Last Updated',
              sortBy: 'updated_at'
            },
            { key: 'id', name: 'ID' }
          ]}
          defaultSort={{ order: -1, key: 'updated_at' }}
          useSearch
          name='Extraction'
          onCreate={() => setCreate(true)}
          onSelect={(extraction: any) =>
            history.push(`/ai/${extraction.id}/configuration`)
          }
          onDelete={setExtractionToDelete}
          onCopy={(extraction: any) =>
            createAIExtraction({ copy_extraction_key: extraction.key })
          }
        />
      </div>
    </>
  );
}
