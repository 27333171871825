import { memo, useMemo, useState } from 'react';
import {
  DropdownField,
  InlineTooltip,
  NumberInput,
  PropertyLabel,
  RadioButtonGroup,
  SoloImageField
} from '../../../Core';
import {
  HorizontalCenterIcon,
  HorizontalLeftIcon,
  HorizontalRightIcon,
  VerticalBottomIcon,
  VerticalCenterIcon,
  VerticalTopIcon
} from '../../../Icons';
import { ImageSizeXY } from './components';
import styles from './styles.module.scss';
import { objectPick } from '../../../../utils/core';
import Row from '../../../Core/Layout/Row';
import Col from '../../../Core/Layout/Col';
import FieldSelectorWithModal from '../../../Modals/FieldSelectorWithModal';
import { IMAGE_FIELDS } from '../../../SelectionPanel/elementEntries';
import { useAppSelector } from '../../../../hooks';

const BackgroundImageSection = ({
  imageId,
  styles: imageStyles,
  onStyleChange = () => {},
  labelData = () => {}
}: any) => {
  const activeStepId = useAppSelector((s: any) => s.formBuilder.activeStepId);

  const defaultStyles = useMemo(() => {
    return objectPick(FeatheryConfig.default_container_styles, [
      'uploaded_image_file_field',
      'uploaded_image_file_field_type',
      'background_image_display',
      'background_image_size',
      'background_image_size_x',
      'background_image_size_y',
      'background_image_repeat',
      'background_image_layout',
      'background_image_vertical_layout'
    ]);
  }, []);

  const [defaultImageSize, setDefaultImageSize] = useState({
    width: (defaultStyles as any).background_image_size_x,
    height: (defaultStyles as any).background_image_size_y
  });

  const handleImageChange = (newImage: any) => {
    const imageId = newImage?.id || '';
    const imageUrl = newImage?.file || '';

    onStyleChange({
      ...defaultStyles,
      background_image_id: imageId,
      background_image_url: imageUrl
    });
  };

  const handleUploadedUrlChange = (data: any) => {
    onStyleChange({
      ...defaultStyles,
      uploaded_image_file_field: data.selectId,
      uploaded_image_file_field_type: data.selectType
    });
  };

  const handleDisplayChange = ({ currentTarget: { value } }: any) => {
    const newStyles = {
      background_image_repeat: 'no-repeat'
    };

    switch (value) {
      case 'fill':
        Object.assign(newStyles, {
          background_image_display: value,
          background_image_size: 'cover'
        });
        break;
      case 'fit':
        Object.assign(newStyles, {
          background_image_display: value,
          background_image_size: 'contain'
        });
        break;
      case 'tile':
        Object.assign(newStyles, {
          background_image_display: value,
          background_image_size: 100,
          background_image_repeat: 'repeat'
        });
        break;
      case 'set_scale':
        Object.assign(newStyles, {
          background_image_display: value,
          background_image_size: undefined,
          background_image_size_x: defaultImageSize.width ?? 200,
          background_image_size_y: defaultImageSize.height ?? 200
        });
        break;
      default:
        break;
    }

    onStyleChange(newStyles);
  };

  return (
    <>
      <Row>
        <Col sm='3'>
          <PropertyLabel
            label='Image'
            centered={false}
            {...labelData('background_image_id', 'background_image_url')}
          />
        </Col>
        <Col sm='1' />
        <Col>
          <SoloImageField
            fileTypes={['image/*']}
            imageId={imageId}
            // @ts-expect-error TS(2322) FIXME: Type '(newImage: any) => void' is not assignable t... Remove this comment to see the full error message
            setImage={handleImageChange}
            // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<{ width: any; height... Remove this comment to see the full error message
            onLoad={setDefaultImageSize}
          />
        </Col>
      </Row>
      <Row>
        <Col sm='5'>
          <PropertyLabel
            label={
              <>
                Show Image from Field
                <InlineTooltip text="The image will be rendered from the field value, whether it's an uploaded image or URL" />
              </>
            }
            {...labelData(
              'uploaded_image_file_field',
              'uploaded_image_file_field_type'
            )}
          />
        </Col>
        <Col>
          <FieldSelectorWithModal
            selectId={imageStyles.uploaded_image_file_field}
            selectType={imageStyles.uploaded_image_file_field_type}
            onSelect={(data: any) => handleUploadedUrlChange(data)}
            currentStepId={activeStepId}
            inFormBuilder
            includeServarTypes={IMAGE_FIELDS as any}
          />
        </Col>
      </Row>
      {(imageId || imageStyles.uploaded_image_file_field) && (
        <>
          <Row>
            <Col sm='3'>
              <PropertyLabel
                label='Display'
                centered={false}
                {...labelData(
                  'background_image_display',
                  'background_image_size',
                  'background_image_repeat',
                  'background_image_size_x',
                  'background_image_size_y'
                )}
              />
            </Col>
            <Col>
              <div className={styles.imagePropertiesDisplayField}>
                <DropdownField
                  onChange={handleDisplayChange}
                  selected={imageStyles?.background_image_display}
                  options={[
                    { value: 'fill', display: 'Fill' },
                    { value: 'fit', display: 'Fit' },
                    { value: 'tile', display: 'Tile' },
                    { value: 'set_scale', display: 'Set Scale' }
                  ]}
                />
              </div>
            </Col>
          </Row>
          {imageStyles?.background_image_display === 'tile' && (
            <Row>
              <Col sm='3'>
                <PropertyLabel
                  label='Scale'
                  centered={false}
                  {...labelData('background_image_size')}
                />
              </Col>
              <Col>
                <div className={styles.imagePropertiesScaleField}>
                  <NumberInput
                    required
                    min={0}
                    value={imageStyles?.background_image_size}
                    units={['%']}
                    onComplete={({ value }: any) =>
                      onStyleChange({ background_image_size: value })
                    }
                  />
                </div>
              </Col>
            </Row>
          )}
          {imageStyles?.background_image_display === 'set_scale' && (
            <Row>
              <ImageSizeXY
                image={imageStyles?.background_image_url}
                x={imageStyles?.background_image_size_x}
                y={imageStyles?.background_image_size_y}
                onChange={onStyleChange}
              />
            </Row>
          )}
          <Row>
            <Col sm='3'>
              <PropertyLabel
                label='Align'
                centered={false}
                {...labelData(
                  'background_image_layout',
                  'background_image_vertical_layout'
                )}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={imageStyles?.background_image_layout}
                name='button-editor-horizontal-alignment'
                onChange={(value: any) =>
                  onStyleChange({ background_image_layout: value })
                }
                options={[
                  {
                    value: 'left',
                    display: <HorizontalLeftIcon />
                  },
                  { value: 'center', display: <HorizontalCenterIcon /> },
                  { value: 'right', display: <HorizontalRightIcon /> }
                ]}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={imageStyles?.background_image_vertical_layout}
                name='button-editor-vertical-alignment'
                onChange={(value: any) =>
                  onStyleChange({ background_image_vertical_layout: value })
                }
                options={[
                  { value: 'top', display: <VerticalTopIcon /> },
                  { value: 'center', display: <VerticalCenterIcon /> },
                  { value: 'bottom', display: <VerticalBottomIcon /> }
                ]}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default memo(BackgroundImageSection);
