/* eslint-disable react-hooks/exhaustive-deps */

import classNames from 'classnames';
import { forwardRef, memo, useEffect, useState } from 'react';
import useCleanUp from '../../../utils/useCleanUp';
import styles from './styles.module.scss';
import ExpandingTextarea from '../ExpandingTextarea';
import ExpandingInput from '../ExpandingInput';

function TextField(
  {
    value,
    autoFocus = false,
    allowedRegex,
    className,
    type = 'text',
    error,
    errorMessage = 'Required',
    onComplete = () => {},
    onChange = () => {},
    onKeyDown = () => {},
    onBlur = () => {},
    // In the detail panel, propagate unsaved changes if focus changes
    triggerCleanUp = false,
    ...props
  }: any,
  ref: any
) {
  // Maintain internal value here so we get immediate updates in the input element
  // Any updates from externally (i.e. something updates `value` prop) will be handled by the useEffect
  //
  // Note: If `value` is set to the same value twice in a row and neither onChange nor onComplete were called
  // then it might make sense to reset this field value to `value`. However, the useEffect won't run since `value`
  // hasn't actually changed. In this case, the field would be out-of-date. To fix this, provide a `key` wherever
  // you call <TextField /> and update that key to force a re-render to occur (similar to how uncontrolled inputs work).
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  const { clearCleanUp, setCleanUp } = useCleanUp({ triggerCleanUp });

  const fieldProps = {
    className: classNames(className, 'form-control', error && styles.error),
    autoFocus,
    value: internalValue,
    ref,
    type,
    onKeyDown: (e: any) => {
      if (e.key === 'Enter') onComplete(e.target.value);
      onKeyDown(e);
    },
    onBlur: (e: any) => {
      onComplete(e.target.value);
      clearCleanUp();
      onBlur(e);
    },
    onChange: (e: any) => {
      const newVal = allowedRegex
        ? e.target.value.replace(allowedRegex, '')
        : e.target.value;

      setInternalValue(newVal);
      setCleanUp(() => onComplete(newVal));
      onChange(newVal);
    },
    ...props
  };

  let component;
  switch (type) {
    case 'expanding-textarea':
      component = <ExpandingTextarea {...fieldProps} />;
      break;
    case 'expanding-input':
      component = <ExpandingInput {...fieldProps} />;
      break;
    case 'textarea':
      component = <textarea {...fieldProps} />;
      break;
    default:
      component = <input {...fieldProps} />;
  }

  return (
    <>
      {component}
      {error && errorMessage && (
        <div className={styles.errorText}>{errorMessage}</div>
      )}
    </>
  );
}

export default memo(forwardRef(TextField));
