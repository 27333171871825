import SettingsContainer from '../../../components/SettingsContainer';
import CollaboratorConfig from '../../../components/Modals/UserCollaborationModal/CollaboratorConfig';
import { InlineTooltip, YesNoSwitch } from '../../../components/Core';

const CollaborationSection = ({ title, formData, handleValueChange }: any) => {
  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <label>
          <p className='form-label'>
            Enable Collaborative Workflows
            <InlineTooltip text='Allow multiple users to collaborate on the same submission.' />
          </p>
          <YesNoSwitch
            id='enable-form-collaboration'
            checked={formData.collaboration_enabled ?? false}
            onCheckedChange={(val: any) =>
              handleValueChange('collaboration_enabled')(val)
            }
          />
        </label>
      </div>
      {formData.collaboration_enabled && (
        <>
          <CollaboratorConfig
            anonymousCollaboration={formData.anonymous_collaboration ?? false}
            setAnonymousCollaboration={(val: any) =>
              handleValueChange('anonymous_collaboration')(val)
            }
            nonCollaborationDisabled={
              formData.non_collaboration_disabled ?? false
            }
            setNonCollaborationDisabled={(val: any) =>
              handleValueChange('non_collaboration_disabled')(val)
            }
            collaboratorOrdered={formData.collaborator_ordered ?? false}
            setCollaboratorOrdered={(val: any) =>
              handleValueChange('collaborator_ordered')(val)
            }
            collaboratorTemplate={formData.collaborator_template}
            setCollaboratorTemplate={(val: any) =>
              handleValueChange('collaborator_template')(val)
            }
          />
          <div className='field-group' style={{ marginTop: '50px' }}>
            <label>
              <p className='form-label'>
                Disable Invite Emails
                <InlineTooltip text="When inviting a new collaborator, they won't receive an invite email and will need to access the Task View to see their task." />
              </p>
              <YesNoSwitch
                id='disable-collaboration-emails'
                checked={formData.disable_collaboration_emails ?? false}
                onCheckedChange={(val: any) =>
                  handleValueChange('disable_collaboration_emails')(val)
                }
              />
            </label>
          </div>
        </>
      )}
    </SettingsContainer>
  );
};

export default CollaborationSection;
