import { UNDO_TITLES, UNDO_TYPES } from '../utils/constants';
import {
  deepEquals,
  objectApply,
  objectPick,
  objectRemove
} from '../utils/core';

import { DRAFT_STATUS, parseError, PUBLISH_STATUS } from './utils';
import {
  manageUndoStack,
  setPanelDataWithDeltas,
  setPanelDataWithUndoRedo,
  updateServars
} from './formBuilderUtils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const flowBuilderInitialState = {
  theme: null,
  // themeBaseline is the starting point for the theme at the start
  // of an editing session (or immediately after an auto-save).  On auto save,
  // the theme is diff'd against the themeBaseline to get deltas to send to the BE.
  // After the auto save, the themeBaseline is set to the theme to begin a new cycle.
  themeBaseline: null,
  formId: '',
  activeStepId: '',
  workingSteps: {},
  changedSteps: {},
  workingLogicRules: {},
  changedLogicRules: {},
  workingLogicRulesInitialized: false,
  ruleBuilderDSL: null,
  undoStack: [],
  redoStack: [],
  changedServars: [],
  themePublishStatus: PUBLISH_STATUS.FULFILLED,
  flowPublishStatus: PUBLISH_STATUS.FULFILLED,
  draftStatus: DRAFT_STATUS.OPERATIONAL,
  draftTimestamp: null,
  unsavedChanges: false,
  codeEditorMaximized: false
};

const defaultModalState = {
  elementId: null,
  elementType: null
};

const stepEditorInitialState = {
  activeTab: 'styles',
  activeButtonTab: 'properties',
  activeContainerTab: 'styles',
  isDragging: false,
  showImageModal: false,
  textSelection: null,
  editingText: false,
  focusedElement: defaultModalState,
  oldServars: {},
  oldServarUsage: {},
  servars: {},
  servarUsage: {},
  accountData: { showConnections: false },
  detailError: '',
  gigPosition: [],
  isModalOpen: false,
  quikMultiForm: false
};

const getDraft = createAsyncThunk(
  'formBuilder/getDraft',
  async ({
    token,
    panelId,
    forceLoad
  }: {
    token: string;
    panelId: string;
    forceLoad?: boolean;
  }) => {
    const response = await FeatheryAPI.getPanelDelta(token, {
      panelId,
      forceLoad
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      let error = await response.text();
      if (!error) error = 'An error occurred while getting the draft';
      return { error };
    }
  }
);

const handleVersionResponse = async (response: any) => {
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 409) {
    // version conflict
    return {
      ...(await response.json()),
      error: 'Form version out of date',
      versionConflict: true
    };
  } else if (response.status === 404) {
    // form deleted
    return {
      error: 'Form not found',
      form_version: '-1',
      timestamp: ''
    };
  } else {
    let error;
    let userError = true;
    try {
      error = parseError(await response.json(), 'An error occurred').message;
    } catch (e) {
      userError = false;
      error = await response.text();
    }
    if (!error) error = 'An error occurred';
    return { error, fatalError: true, userError };
  }
};

const updateDraft = createAsyncThunk(
  'formBuilder/updateDraft',
  async ({
    token,
    panelId,
    data
  }: {
    token: string;
    panelId: string;
    data: Record<string, any>;
  }) => {
    const response = await FeatheryAPI.updatePanelDelta(token, {
      panelId,
      data
    });
    return await handleVersionResponse(response);
  }
);

const deleteDraft = createAsyncThunk(
  'formBuilder/deleteDraft',
  async ({
    token,
    panelId,
    data
  }: {
    token: string;
    panelId: string;
    data: Record<string, any>;
  }) => {
    const response = await FeatheryAPI.deletePanelDelta(token, {
      panelId,
      data
    });
    return await handleVersionResponse(response);
  }
);

const publishForm = createAsyncThunk(
  'formBuilder/publishForm',
  async ({
    token,
    panelId,
    body
  }: {
    token: string;
    panelId: string;
    body: any;
  }) => {
    const response = await FeatheryAPI.putPanelStepSnapshot(token, {
      panelId,
      body
    });
    return await handleVersionResponse(response);
  }
);

const wipeElementFocus = (state: any) => {
  state.focusedElement = { ...defaultModalState };
  state.detailError = '';
  state.textSelection = null;
  state.editingText = false;
};

const initialState = {
  ...flowBuilderInitialState,
  ...stepEditorInitialState
};
const formBuilderSlice = createSlice({
  name: 'formBuilder',
  initialState: JSON.parse(JSON.stringify(initialState)),
  reducers: {
    // *********
    // Flow Builder Reducers
    // *********
    resetToInitialState(state) {
      Object.assign(state, JSON.parse(JSON.stringify(initialState)));
    },
    addThemeToUndoStack(state, action) {
      // set theme here
      state.theme = action.payload.newValue;
      state.unsavedChanges = true;
      state.themePublishStatus = PUBLISH_STATUS.ACTIVE;
      state.flowPublishStatus = PUBLISH_STATUS.ACTIVE;
      manageUndoStack(state);
      let last = state.undoStack.pop();
      // this is the model that gets pushed onto the stack - id, old value, and new value
      const copyChange = {
        themeOldValue: action.payload.oldValue,
        themeNewValue: action.payload.newValue
      };
      (copyChange as any).undoType = action.payload.type;
      last = { ...last, ...copyChange };
      // push the change to the undo stack, and also clear anything in the redo stack because there's been new changes
      state.undoStack = [...state.undoStack, last];
      state.redoStack = [];
    },
    addRuleActionToUndoStack(state, action) {
      manageUndoStack(state);

      const copyChange = {
        undoType: action.payload.type,
        ruleBuilderDSLOldValue: action.payload.oldValue,
        ruleBuilderDSLNewValue: action.payload.newValue
      };

      state.undoStack = [...state.undoStack, copyChange];
      state.redoStack = [];
    },
    toggleQuikMultiForm(state) {
      state.quikMultiForm = !state.quikMultiForm;
    },
    handleUndo(state) {
      // pop the top of the undo stack over onto the redo stack, copy the change out so we don't have async problems getting the right element
      const undoStack = state.undoStack[state.undoStack.length - 1];
      if (!undoStack) return;

      const copyStepForChange = JSON.parse(JSON.stringify(undoStack));

      state.redoStack = [...state.redoStack, ...state.undoStack.slice(-1)];
      state.undoStack = [...state.undoStack.slice(0, -1)];

      if (copyStepForChange.undoType === UNDO_TYPES.RULE_BUILDER) {
        state.ruleBuilderDSL = copyStepForChange.ruleBuilderDSLOldValue;
        return; // Don't do anything else if the undo type is Rule Builder
      }

      // If we're undoing an element addition, make sure to unset focus so as to not break things
      if (copyStepForChange.undoTitle === UNDO_TITLES.NEW_ELEMENT) {
        state.focusedElement = { ...defaultModalState };
        state.detailError = '';
      }

      // The rules to undo are by default determined from oldRulesValue
      const newWorkingLogicRules = objectApply(
        state.workingLogicRules,
        copyStepForChange.oldRulesValue
      );
      const changedRuleKeys = Object.keys(
        copyStepForChange.oldRulesValue ?? {}
      );

      // set the currently active step, theme, or conditions to the new top of the undo stack
      if (copyStepForChange.undoType === UNDO_TYPES.THEME) {
        state.theme = {
          ...state.theme,
          ...copyStepForChange.themeOldValue
        };
        state.unsavedChanges = true;
        // update the working step and step changes
        setPanelDataWithDeltas(
          state,
          objectApply(state.workingSteps, {
            [copyStepForChange.id]: copyStepForChange.oldValue
          }),
          [copyStepForChange.id],
          newWorkingLogicRules,
          changedRuleKeys
        );
        state.themePublishStatus = PUBLISH_STATUS.ACTIVE;
      } else if (copyStepForChange.undoType === UNDO_TYPES.STEP) {
        setPanelDataWithDeltas(
          state,
          objectApply(state.workingSteps, {
            [copyStepForChange.id]: copyStepForChange.oldValue
          }),
          [copyStepForChange.id],
          newWorkingLogicRules,
          changedRuleKeys
        );
      } else if (copyStepForChange.undoType === UNDO_TYPES.SOME_STEPS) {
        setPanelDataWithDeltas(
          state,
          objectApply(state.workingSteps, copyStepForChange.oldValue),
          Object.keys(copyStepForChange.oldValue),
          newWorkingLogicRules,
          changedRuleKeys
        );
      } else if (copyStepForChange.undoType === UNDO_TYPES.CONDITION) {
        // undoing adding a step is a little tricky if you're on the step, special case
        if (
          copyStepForChange.undoTitle === UNDO_TITLES.NEW_STEP &&
          state.activeStepId === copyStepForChange.id
        ) {
          state.activeStepId = '';
        }
        // these special cases allow us to not add ALL the working steps to the undo/redo stacks when a step is added or deleted
        if (copyStepForChange.undoTitle === UNDO_TITLES.DELETE) {
          setPanelDataWithDeltas(
            state,
            {
              ...state.workingSteps,
              ...copyStepForChange.oldValue
            },
            Object.keys(copyStepForChange.oldValue),
            newWorkingLogicRules,
            changedRuleKeys
          );
        } else if (copyStepForChange.undoTitle === UNDO_TITLES.NEW_STEP) {
          setPanelDataWithDeltas(
            state,
            objectRemove(state.workingSteps, [copyStepForChange.id]),
            [copyStepForChange.id],
            newWorkingLogicRules,
            changedRuleKeys
          );
        } else {
          setPanelDataWithDeltas(
            state,
            {
              ...state.workingSteps,
              ...copyStepForChange.oldValue
            },
            Object.keys(copyStepForChange.oldValue),
            newWorkingLogicRules,
            changedRuleKeys
          );
        }
      } else if (copyStepForChange.undoType === UNDO_TYPES.LOGIC_RULES) {
        if (copyStepForChange.undoTitle === UNDO_TITLES.DELETE_LOGIC_RULE) {
          setPanelDataWithDeltas(
            state,
            objectApply(state.workingSteps, copyStepForChange.oldValue),
            Object.keys(copyStepForChange.oldValue),
            newWorkingLogicRules,
            changedRuleKeys
          );
        } else if (copyStepForChange.undoTitle === UNDO_TITLES.NEW_LOGIC_RULE) {
          setPanelDataWithDeltas(
            state,
            objectApply(state.workingSteps, copyStepForChange.oldValue),
            Object.keys(copyStepForChange.oldValue),
            objectRemove(
              state.workingLogicRules,
              Object.keys(copyStepForChange.newRulesValue)
            ),
            Object.keys(copyStepForChange.newRulesValue)
          );
        } else {
          // rule change
          setPanelDataWithDeltas(
            state,
            objectApply(state.workingSteps, copyStepForChange.oldValue),
            Object.keys(copyStepForChange.oldValue),
            newWorkingLogicRules,
            changedRuleKeys
          );
        }
      }

      state.flowPublishStatus = PUBLISH_STATUS.ACTIVE;
      updateServars(state, state.workingSteps);
      wipeElementFocus(state);
    },
    handleRedo(state) {
      // pop the top of the redo stack over onto the undo stack, copy the change out so we don't have async problems getting the right element
      const redoStack = state.redoStack[state.redoStack.length - 1];
      if (!redoStack) return;

      const copyStepForChange = JSON.parse(JSON.stringify(redoStack));
      state.undoStack = [...state.undoStack, ...state.redoStack.slice(-1)];
      state.redoStack = [...state.redoStack.slice(0, -1)];

      if (copyStepForChange.undoType === UNDO_TYPES.RULE_BUILDER) {
        state.ruleBuilderDSL = copyStepForChange.ruleBuilderDSLNewValue;
        return; // Don't do anything else if the undo type is Rule Builder
      }

      // The rules to redo are by default determined from newRulesValue
      const newWorkingLogicRules = objectApply(
        state.workingLogicRules,
        copyStepForChange.newRulesValue
      );
      const changedRuleKeys = Object.keys(
        copyStepForChange.newRulesValue ?? {}
      );

      // set the currently active step, theme, or conditions to the new top of the undo stack
      if (copyStepForChange.undoType === UNDO_TYPES.THEME) {
        state.theme = {
          ...state.theme,
          ...copyStepForChange.themeNewValue
        };
        state.unsavedChanges = true;
        setPanelDataWithDeltas(
          state,
          objectApply(state.workingSteps, {
            [copyStepForChange.id]: copyStepForChange.newValue
          }),
          [copyStepForChange.id],
          newWorkingLogicRules,
          changedRuleKeys
        );
        state.themePublishStatus = PUBLISH_STATUS.ACTIVE;
      } else if (copyStepForChange.undoType === UNDO_TYPES.STEP) {
        setPanelDataWithDeltas(
          state,
          objectApply(state.workingSteps, {
            [copyStepForChange.id]: copyStepForChange.newValue
          }),
          [copyStepForChange.id],
          newWorkingLogicRules,
          changedRuleKeys
        );
      } else if (copyStepForChange.undoType === UNDO_TYPES.SOME_STEPS) {
        setPanelDataWithDeltas(
          state,
          objectApply(state.workingSteps, copyStepForChange.newValue),
          Object.keys(copyStepForChange.oldValue),
          newWorkingLogicRules,
          changedRuleKeys
        );
      } else if (copyStepForChange.undoType === UNDO_TYPES.CONDITION) {
        // redoing deleting a step can be a little tricky if you're on the step, special case
        if (
          copyStepForChange.undoTitle === UNDO_TITLES.DELETE &&
          state.activeStepId === copyStepForChange.id
        ) {
          state.activeStepId = '';
        }
        // these special cases allow us to not add ALL the working steps to the undo/redo stacks when a step is added or deleted
        if (copyStepForChange.undoTitle === UNDO_TITLES.DELETE) {
          setPanelDataWithDeltas(
            state,
            objectRemove(state.workingSteps, [copyStepForChange.id]),
            [copyStepForChange.id],
            newWorkingLogicRules,
            changedRuleKeys
          );
          setPanelDataWithDeltas(
            state,
            {
              ...state.workingSteps,
              ...copyStepForChange.newValue
            },
            Object.keys(copyStepForChange.newValue),
            newWorkingLogicRules,
            changedRuleKeys
          );
        } else if (copyStepForChange.undoTitle === UNDO_TITLES.NEW_STEP) {
          setPanelDataWithDeltas(
            state,
            {
              ...state.workingSteps,
              ...copyStepForChange.newValue
            },
            Object.keys(copyStepForChange.newValue),
            newWorkingLogicRules,
            changedRuleKeys
          );
        } else {
          setPanelDataWithDeltas(
            state,
            {
              ...state.workingSteps,
              ...copyStepForChange.newValue
            },
            Object.keys(copyStepForChange.newValue),
            newWorkingLogicRules,
            changedRuleKeys
          );
        }
      } else if (copyStepForChange.undoType === UNDO_TYPES.LOGIC_RULES) {
        if (copyStepForChange.undoTitle === UNDO_TITLES.DELETE_LOGIC_RULE) {
          setPanelDataWithDeltas(
            state,
            objectApply(state.workingSteps, copyStepForChange.newValue),
            Object.keys(copyStepForChange.oldValue),
            objectRemove(
              state.workingLogicRules,
              Object.keys(copyStepForChange.oldRulesValue)
            ),
            Object.keys(copyStepForChange.oldRulesValue)
          );
        } else if (copyStepForChange.undoTitle === UNDO_TITLES.NEW_LOGIC_RULE) {
          setPanelDataWithDeltas(
            state,
            objectApply(state.workingSteps, copyStepForChange.newValue),
            Object.keys(copyStepForChange.oldValue),
            newWorkingLogicRules,
            changedRuleKeys
          );
        } else {
          // rule change
          setPanelDataWithDeltas(
            state,
            objectApply(state.workingSteps, copyStepForChange.newValue),
            Object.keys(copyStepForChange.oldValue),
            newWorkingLogicRules,
            changedRuleKeys
          );
        }
      }
      state.flowPublishStatus = PUBLISH_STATUS.ACTIVE;
      updateServars(state, state.workingSteps);
      wipeElementFocus(state);
    },
    popUndoStack(state) {
      state.redoStack = [...state.redoStack, ...state.undoStack.slice(-1)];
      state.undoStack = [...state.undoStack.slice(0, -1)];
    },
    popRedoStack(state) {
      state.undoStack = [...state.undoStack, ...state.redoStack.slice(-1)];
      state.redoStack = [...state.redoStack.slice(0, -1)];
    },
    clearUndoRedoStacks(state) {
      state.undoStack = [];
      state.redoStack = [];
    },
    filterUndoRedoStacksByType(state, action) {
      const type = action.payload;

      state.undoStack = state.undoStack.filter((change: any) => {
        return change.undoType !== type;
      });

      state.redoStack = state.redoStack.filter((change: any) => {
        return change.undoType !== type;
      });
    },
    clearError(state) {
      state.error = '';
    },
    setError(state, action) {
      state.error = action?.payload || '';
    },
    clearUnsavedChanges(state) {
      state.unsavedChanges = false;
    },
    setPanelDataWithUndoRedo,
    updateNavRulesByStep(state, action) {
      const { stepId, newData, formId } = action.payload;
      const newLayers: any = { [stepId]: { next_conditions: newData } };
      const changedStepKeys = [stepId];
      Object.values(state.workingSteps).forEach((step: any) => {
        if (step.id === stepId) return;

        // is this step changing?
        if (
          step.previous_conditions.some(
            (cond: any) => cond.previous_step === stepId
          ) ||
          newData.some((cond: any) => cond.next_step === step.id)
        )
          changedStepKeys.push(step.id);

        const filteredConditions = step.previous_conditions.filter(
          (cond: any) => cond.previous_step !== stepId
        );
        newLayers[step.id] = {
          previous_conditions: [
            ...filteredConditions,
            ...newData.filter((cond: any) => cond.next_step === step.id)
          ]
        };
      });
      // Update working steps with the new info
      const newWorkingSteps: any = objectApply(state.workingSteps, newLayers);
      setPanelDataWithUndoRedo(state, {
        payload: {
          id: formId,
          oldValue: objectPick(state.workingSteps, changedStepKeys),
          newValue: objectPick(newWorkingSteps, changedStepKeys),
          title: UNDO_TITLES.CONDITION,
          type: UNDO_TYPES.CONDITION,
          workingSteps: newWorkingSteps
        }
      });
    },
    updateNavRulesByPair(state, action) {
      const { prevStepId, nextStepId, newData, formId } = action.payload;
      const prevStep = state.workingSteps[prevStepId];
      const nextStep = state.workingSteps[nextStepId];
      // Create the new conditions going from the previous to next step and vice versa
      let nextConditions = prevStep.next_conditions.filter(
        (cond: any) => cond.next_step !== nextStepId
      );
      nextConditions = nextConditions.concat(newData);
      let prevConditions = nextStep.previous_conditions.filter(
        (cond: any) => cond.previous_step !== prevStepId
      );
      prevConditions = prevConditions.concat(newData);
      // Update working steps with the new info
      const newWorkingSteps = objectApply(state.workingSteps, {
        [prevStepId]: { next_conditions: nextConditions },
        [nextStepId]: { previous_conditions: prevConditions }
      });
      const changedStepKeys = [prevStepId, nextStepId];
      setPanelDataWithUndoRedo(state, {
        payload: {
          id: formId,
          oldValue: objectPick(state.workingSteps, changedStepKeys),
          newValue: objectPick(newWorkingSteps, changedStepKeys),
          title: UNDO_TITLES.CONDITION,
          type: UNDO_TYPES.CONDITION,
          workingSteps: newWorkingSteps
        }
      });
    },
    updateNavRulesByElement(state, action) {
      const { prevStepId, nextStepId, elementId, newData, formId } =
        action.payload;
      const prevStep = state.workingSteps[prevStepId];
      const newLayers: any = {};
      // This logic removes all other nav rules from this element and only leaves
      //  this new one.
      const nextConditions = prevStep.next_conditions.filter(
        (cond: any) => cond.element_id !== elementId
      );
      newLayers[prevStepId] = {
        next_conditions: nextConditions.concat(newData)
      };
      Object.values(state.workingSteps).forEach((step: any) => {
        if (step.id === prevStepId) return;

        const filteredConditions = step.previous_conditions.filter(
          (cond: any) => cond.element_id !== elementId
        );
        if (step.id === nextStepId)
          newLayers[step.id] = {
            previous_conditions: [...filteredConditions, ...newData]
          };
        else if (filteredConditions.length < step.previous_conditions.length) {
          newLayers[step.id] = { previous_conditions: filteredConditions };
        }
      });
      // Update working steps with the new info
      const newWorkingSteps: any = objectApply(state.workingSteps, newLayers);
      const changedStepKeys = Object.keys(newLayers);
      setPanelDataWithUndoRedo(state, {
        payload: {
          id: formId,
          oldValue: objectPick(state.workingSteps, changedStepKeys),
          newValue: objectPick(newWorkingSteps, changedStepKeys),
          title: UNDO_TITLES.CONDITION,
          type: UNDO_TYPES.CONDITION,
          workingSteps: newWorkingSteps
        }
      });
    },
    setTheme(state, action) {
      state.theme = action.payload.theme;
      state.unsavedChanges = true;
      if (action.payload.rebase) state.themeBaseline = action.payload.theme;
      state.themePublishStatus = action.payload.status ?? PUBLISH_STATUS.ACTIVE;
      state.flowPublishStatus =
        action.payload.flowStatus ?? PUBLISH_STATUS.ACTIVE;
    },
    setWorkingSteps(state, action) {
      const { workingSteps, status, changedStepKeys } = action.payload;
      if (changedStepKeys)
        setPanelDataWithDeltas(state, workingSteps, changedStepKeys);
      else {
        state.workingSteps = workingSteps;
        state.changedSteps = {};
      }
      state.flowPublishStatus = status ?? PUBLISH_STATUS.ACTIVE;
      updateServars(state, workingSteps);
    },
    setWorkingLogicRules(state, action) {
      state.workingLogicRules = action.payload.workingLogicRules;
      state.changedLogicRules = {};
      state.workingLogicRulesInitialized = true;
      state.flowPublishStatus = action.payload.status ?? PUBLISH_STATUS.ACTIVE;
      state.unsavedChanges = false;
    },
    setFormId(state, action) {
      state.formId = action.payload;
    },
    setActiveStepId(state, action) {
      state.gigPosition = [];
      state.activeStepId = action.payload;
    },
    setThemePublishStatus(state, action) {
      state.themePublishStatus = action.payload;
    },
    setFlowPublishStatus(state, action) {
      state.flowPublishStatus = action.payload;
    },
    setDraftStatus(state, action) {
      state.draftStatus = action.payload;
    },
    setChangedServars(state, action) {
      state.changedServars = action.payload;
    },
    addChangedServar(state, action) {
      const fieldId = action.payload;
      if (!state.changedServars.includes(fieldId))
        state.changedServars = [...state.changedServars, fieldId];
    },
    setRuleBuilderDSL(state, action) {
      state.ruleBuilderDSL = action.payload;
    },
    // *********
    // Step Editor Reducers
    // *********
    selectTab(state, action) {
      state.activeTab = action.payload;
    },
    selectButtonTab(state, action) {
      state.activeButtonTab = action.payload;
    },
    selectContainerTab(state, action) {
      state.activeContainerTab = action.payload;
    },
    updateDrag(state, action) {
      state.isDragging = action.payload;
    },
    focusElement(state, action) {
      const newElement = {
        ...defaultModalState,
        ...(action.payload ?? {})
      };

      if (newElement.elementId !== state.focusedElement.elementId) {
        state.focusedElement = newElement;
        state.detailError = '';
        state.textSelection = null;
      }
    },
    gigSetPosition(state, action) {
      const newPosition = action.payload ?? [];
      if (!deepEquals(newPosition, state.gigPosition)) {
        state.gigPosition = newPosition;
        state.textSelection = null;
      }
    },
    wipeFocus(state) {
      wipeElementFocus(state);
    },
    setEditingText(state, action) {
      state.editingText = action.payload;
    },
    setTextSelection(state, action) {
      state.textSelection = action.payload;
    },
    setShowImageModal(state, action) {
      state.showImageModal = action.payload;
    },
    setDetailError(state, action) {
      state.detailError = action.payload;
    },
    setServarCache(state, action) {
      const { servars, usage } = action.payload;
      // Filter out state from the current form
      state.oldServarUsage = Object.entries(usage).reduce(
        (newUsage, [servarId, data]) => {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          newUsage[servarId] = (data as any).filter(
            // @ts-expect-error TS(7031) FIXME: Binding element 'panelId' implicitly has an 'any' ... Remove this comment to see the full error message
            ({ panel_id: panelId }) => panelId !== state.formId
          );
          return newUsage;
        },
        {}
      );
      state.oldServars = Object.entries(servars).reduce(
        (newServars, [servarId, servarObj]) => {
          if (state.oldServarUsage[servarId]?.length)
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            newServars[servarId] = servarObj;
          return newServars;
        },
        {}
      );
      state.servars = servars;
      state.servarUsage = usage;
    },
    setAccountData(state, action) {
      state.accountData = { ...state.accountData, ...action.payload };
    },
    setModalOpen(state, action) {
      state.isModalOpen = action.payload;
    },
    setCodeEditorMaximized(state, action) {
      state.codeEditorMaximized = action.payload.maximized;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(publishForm.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        // No error message, successful publish
        state.flowPublishStatus = PUBLISH_STATUS.FULFILLED;
        state.themePublishStatus = PUBLISH_STATUS.FULFILLED;
        state.changedServars = [];
      } else {
        // Reset to error publish statuses
        const { errorFlowStatus, errorThemeStatus } = action.meta.arg;
        state.flowPublishStatus = errorFlowStatus;
        state.themePublishStatus = errorThemeStatus;
      }
    });
    builder.addCase(getDraft.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        // No error message, successful draft retrieval
        state.draftTimestamp = action.payload.timestamp ?? '';
      }
    });
    builder.addCase(updateDraft.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        // No error message, successful auto-save

        // If there has been changes made while doing the autosave. i.e. unsavedChanges set,
        //  then don't wipe the unsaved deltas and simply send them again next cycle.
        if (!state.unsavedChanges) {
          state.changedSteps = {};
          state.changedLogicRules = {};
          state.themeBaseline = state.theme;
        }
        state.draftTimestamp = action.payload.timestamp;
      }
    });
    builder.addCase(deleteDraft.fulfilled, (state: any, action: any) => {
      if (!action.payload.error) {
        // No error message, successful draft delete
        state.draftTimestamp = action.payload.timestamp ?? '';
        state.changedServars = [];
      }
    });
  }
});

export default formBuilderSlice.reducer;
const exportFunctions = {
  syncFunctions: formBuilderSlice.actions,
  asyncFunctions: { getDraft, deleteDraft, updateDraft, publishForm }
};
export { exportFunctions, setPanelDataWithDeltas };
